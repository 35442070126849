import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
//import useStores from "../stores/useStores";
import { observer } from "mobx-react";
import MainLayout from "../Layout/Main";

import StudyNew from "../Pages/Study/New";
import StudySearch from "../Pages/Study/Search";

import BacteriaNew from "../Pages/Bacteria/New";
import BacteriaSearch from "../Pages/Bacteria/Search";
import BacteriaManage from "../Pages/Bacteria/Manage";
import BacteriaEdit from "../Pages/Bacteria/Edit";
import BacteriaSearchLabel from "../Pages/Bacteria/SearchLabel";

import ParasiteNew from "../Pages/Parasite/New";
import ParasiteSearch from "../Pages/Parasite/Search";
import ParasiteSearchLabel from "../Pages/Parasite/SearchLabel";
import ParasiteManage from "../Pages/Parasite/Manage";
import ParasiteEdit from "../Pages/Parasite/Edit";

import VirusNew from "../Pages/Virus/New";
import VirusSearch from "../Pages/Virus/Search";
import VirusSearchLabel from "../Pages/Virus/SearchLabel";
import VirusManage from "../Pages/Virus/Manage";
import VirusEdit from "../Pages/Virus/Edit";

import About from "../Pages/MainMenu/About";
import MetaAnalysis  from "../Pages/MainMenu/MetaAnalysis";
import Distributions  from "../Pages/MainMenu/Distributions";

import UserManage from "../Pages/User/Manage";

import GC from "../Configs/global";

function AdminRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <MainLayout>
          <Route path={GC.routes.menu.default} exact component={About} />
          <Route path={GC.routes.menu.metaAnalysis} exact component={MetaAnalysis} />
          <Route path={GC.routes.menu.distributions} exact component={Distributions} />

          <Route path={GC.routes.bacteria.new} exact component={BacteriaNew} />
          <Route path={GC.routes.bacteria.search} exact component={BacteriaSearch} />     
          <Route path={GC.routes.bacteria.searchLabel} exact component={BacteriaSearchLabel} />     
          <Route path={GC.routes.bacteria.manage} exact component={BacteriaManage} />       
          <Route path={GC.routes.bacteria.manageEdit} exact component={BacteriaEdit} />    

          <Route path={GC.routes.parasite.new} exact component={ParasiteNew} />    
          <Route path={GC.routes.parasite.search} exact component={ParasiteSearch} />    
          <Route path={GC.routes.parasite.searchLabel} exact component={ParasiteSearchLabel} />    
          <Route path={GC.routes.parasite.manage} exact component={ParasiteManage} />  
          <Route path={GC.routes.parasite.manageEdit} exact component={ParasiteEdit} /> 

          <Route path={GC.routes.virus.new} exact component={VirusNew} />    
          <Route path={GC.routes.virus.search} exact component={VirusSearch} />
          <Route path={GC.routes.virus.searchLabel} exact component={VirusSearchLabel} />
          <Route path={GC.routes.virus.manage} exact component={VirusManage} /> 
          <Route path={GC.routes.virus.manageEdit} exact component={VirusEdit} /> 

          <Route path={GC.routes.study.new} exact component={StudyNew} />
          <Route path={GC.routes.study.search} exact component={StudySearch} />

          <Route path={GC.routes.users.manage} exact component={UserManage} />
        </MainLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default observer(AdminRoutes);