export default {
    leave_blank: " (leave blank for NA)",
    required: "  (required)",
    blank_error: "This field can not be empty!",

    dialog_errors: {
      max_attempts: "Error when trying to communicate with the server! Try Again Later!",
      register: "",
      unauthorized: "You are not allowed to perform this action. Log in to the system again!"
    },

    dialog_sucess: {
      register: ""
    },

    page_study_new: {
      dialog_error_register: "Error when trying to register new study!",
      dialog_sucess_register: "Study Registred!",
    },

    snack_msg: {
      error_empty_fields: "Check that all required fields have been completed.",
      reponse_failed: "Failed to communicate with the server",
      register_sucess: "Registration Successful!",
      register_error: "Registration Failed!", 
      update_sucess: "Successfully Updated!", 
      update_error: "Failed to Update!",
      remove_sucess: "Successfully Removed!",
      remove_error: "Failed to Remove!",
    }
  };