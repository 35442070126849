import axios from 'axios';  

axios.defaults.headers.get['Accept'] = 'application/json'   // default header for all get request
axios.defaults.headers.post['Accept'] = 'application/json'

const api = axios.create({
    baseURL: 'https://fsqa.esa.ipb.pt/api',
    headers: {
        "Access-Control-Allow-Origin": "*",
    }
});

const endPoints = {
    bacteria: {

    },
    foods: {

    },
    countries: {
        getOnlyNames: '/countries/list/only_names',
    }
}

export const restAPI = {
    gets() {
        return {
            getCountriesNames: () => api.get(endPoints.countries.getOnlyNames),
        }
    },
}

export const shinyURL = "https://fsqa.esa.ipb.pt/shiny/filter?";

export default api;
