import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { Menu } from '@material-ui/core';

import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';

import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import MaterialInput from "../../../Components/Inputs/MaterialInput";
//import MaterialSelect from "../../../Components/Selects/MaterialSelect";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";
import MaterialMultNativeSelect from "../../../Components/Selects/MaterialMultNativeSelect";
import CountrySelectMult from "../../../Components/CountrySelectMult";

import CustomSelect from "../../../Components/Selects/CustomSelect";

import CustomAutoComplete from "../../../Components/Selects/CustomAutoComplete";

import TableVirtualized from "../../../Components/Tables/TableVirtualized";

import SubmitButton from "../../../Components/Button/SubmitButton"; 

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";

import * as Yup from 'yup';
import useStores from "../../../Stores/useStore";

import api from "../../../Services/api";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { AlertTitle } from '@material-ui/lab';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: 'bold',
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTN: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
}));

export default function ParasiteSearch() {
  const blank_text = Texts.leave_blank;
  const blank_text_error = Texts.blank_error;
  const formRef = useRef(null);
  const { appStore } = useStores();

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [isLoading,setIsLoading] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState(false);

  // JSON GET STRUCTURES (FROM BACK-END)
  const [jsonAgent,setJsonAgent] = useState({}); // Bacterium Tree [Bacterium Filter]
  const [jsonFoods,setJsonFoods] = useState({}); // Food Tree [Food Filter]
  const [jsonResultsData,setJsonResultsData] = useState({}); // Results Data [Advanced Filters]

  const [data, setData] = useState(null);
  const [dataAux, setDataAux] = useState([]);

  // Table Rows
  const [rows,setRows] = useState([]);

  const [openSnackAlert,setOpenSnackAlert] = useState({
    open: false,
    severity: "info",
    title: "",
    msg: "",
  });


  const handleAdvancedFilters = () => {
      setAdvancedFilters((prev) => !prev);
  };

  useEffect(() => {
    callFoodAPI(0);
    callParasiteAPI(0);
  },[]);

  const callParasiteAPI = (attempt) => {
    if (attempt >= 2) {
      setJsonAgent({});
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.reponse_failed
      });
      return ;
    }
    // Run API Call
    api.get('/parasite/struct/all').then(response => {
      //console.log("recebeu:", response.data);
      setJsonAgent(response.data.parasite_tree);
      //setJsonResultsData(response.data.results_data);
      return;
    }).catch(err => {
      callParasiteAPI(attempt+1);
    });
  }

  const callFoodAPI = (attempt) => {
    if (attempt >= 2) {
      setDataAux([]);
      setJsonFoods({});
      setJsonResultsData({});
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.reponse_failed
      });
      return ;
    }
  
    // Run API Call
    api.get('/food/struct/all_parasite').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonFoods(response.data.food_expand_parasite_tree);
        setDataAux([response.data.food_expand_parasite_tree]);
        setJsonResultsData(response.data.food_data);
        return;
    }).catch(err => {
        callFoodAPI(attempt+1);
    });
  };

  async function handleSubmit(data, { reset }) {
    let newData = {};

    Object.keys(data).map(key => {
      if (Array.isArray(data[key])) {
        newData = {...newData, [key]: data[key].toString()}
      } else {
        newData = {...newData, [key]: data[key]}
      }
    });
    ////console.log(Yup.object());
    //console.log("Enviou: ",newData);
    callSearchAPI(newData);
  }

  const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5ODY2ODI5OSwiZXhwIjoxNTk4OTI3NDk5fQ.u-BC6fLLBtB_FKTePdlKXv5ij37yVV8cm_crWMucOdI";
  
    const callBibDownloadAPI = () => {
      let StudyIDs = [];
      if (rows.length != 0) {
        let index = rows[0].findIndex((element) => element = "StudyID");
        if (index != -1) {
          rows.slice(1,rows.length).map(row => {
            if (StudyIDs.findIndex((element) => element == row[index]) == -1) {
              StudyIDs.push(row[index]);
            }
          })
        }
      }
      
      if (StudyIDs.length > 0) {
        api.post('/study/list/bib', {StudyIDs: StudyIDs}, {
          headers: {
            'authorization': authToken
          }
        }).then(response => {

          if (response.data != null) {
            const type = response.headers['content-type'];
            //console.log("Type: ",type);
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'file.bib'
            link.click()
          }

        }).catch(function (error) {
          //console.log("ERROR");
        });
      }
      
      return StudyIDs;
    }

    const callSearchAPI = (data) => {
    setIsLoading(true);
    api
      .get("/parasite/list/search", {params: {...data}, headers: {authorization: authToken}})
      .then((response) => {
        //console.log("recebeu:", response.data);

        if(response.data == [] || response.data == "" || response.data == " ") {
          setRows([]);
          return setIsLoading(false);
        }
        
        // Return JSON as Download File (blob)
        if (data.type == "json") {
          const type = response.headers['content-type']
          const str = JSON.stringify(response.data);
          const bytes = new TextEncoder().encode(str);
          const blob = new Blob([bytes], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'file.'+data.type
          link.click()
        // Return CSV as Download File (blob)
        } else if (data.type == "csv") {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'file.'+data.type
          link.click()
        // Return Table as Visualization in HTML (React)
        } else {
          setRows(response.data);
        }
        return setIsLoading(false);
      })
      .catch(function (error) {
        let exists = false;
        //console.log(error);
        //console.log(error.response);
        try {
          if (
            "response" in error &&
            "data" in error.response &&
            "error" in error.response.data
          ) {
            exists = true;
          }
        } catch {
          exists = false;
        }
        // Snack Error
        setOpenSnackAlert({
            open: true,
            severity: "error",
            title: "Error",
            msg: Texts.snack_msg.reponse_failed
          });
        return setIsLoading(false);
      });
  };

  const handleChange = (label, index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setData({ ...data, [label]: event });

      if (dataAux[index][event] != null) {
        let newData = dataAux;
        newData = newData.slice(0, index + 1);

        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";

        setDataAux(newData);
        /*} else {
            //console.log("Tentou Alterar");
          }*/
      } else {
        let newData = dataAux;
        ////console.log("Entrada: ",newData);
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        ////console.log("Saida: ",newData);
        setDataAux(newData);
      }
    } else {
      ////console.log("entrou1")
      let newData = dataAux;
      ////console.log("Entrada: ",newData);
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      ////console.log("Saida: ",newData);
      setDataAux(newData);
    }
  };

  //const [jsontree,setJsonTree] = useState(jsonFoods);

  const clearData = () => {
    setDataAux([jsonFoods]);
    //setJsonTree(jsonFoods);
    setData(null);
  };

  return (
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Form ref={formRef} className={classes.formWide} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Pathogen</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <MaterialMultNativeSelect
            name="Parasite"
            label={"Select Agent" }
            labelError={blank_text_error}
            multiple
            inputProps={{
              style: {
                  padding: 10,
                  minHeight: "150px",
              }
            }}
          >
            <option value={""}>All</option>
            {Object.keys(jsonAgent).map(key => 
              key != "label" && key != "selected" ? (
                <option key={key} value={key}>
                  {key}
                </option >
              ) : null
            )}
          </MaterialMultNativeSelect>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Type</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <MaterialNativeSelect
            name="essay"
            label={"Select Essay"}
            labelError={blank_text_error}
          >
            <option value={""}>All</option>
            <option value={"prevalence"}>Prevalence</option>
            <option value={"count"}>Enumeration</option>
            <option value={"both"}>Prevalence+Enumeration</option>
          </MaterialNativeSelect>
          </Paper>
        </Grid>

          <Grid item xs={12} md={12}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Food Info
            </Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
              { 
                  (dataAux.map((json,index) => {
                    return (
                    <MaterialNativeSelect
                      key={json.label}
                      label={json.label}
                      labelError={blank_text_error}
                      onChange={(event) => handleChange(json.label,index,event.target.value)}
                      name={json.label}
                    >
                      <option value={""}>All</option >
                      {Object.keys(json).map(key => 
                        key != "label" && key != "selected" ? (
                          <option key={key} value={key}>
                            {key}
                          </option >
                        ) : null
                      )}
                    </MaterialNativeSelect>
                  )}))
              }
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Search Results
            </Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
              <Grid container>
                <Grid container item  xs={12} md={6} direction="column" style={{paddingRight: 5}}>
                  <MaterialNativeSelect
                    name="type"
                    label={"Show Search Results as:"}
                    labelError={blank_text_error}
                  >
                    <option value={"table"}>Table</option>
                    {appStore.isAllowed() ? 
                      <React.Fragment>
                        <option value={"csv"}>CSV (Download)</option>
                        <option value={"json"}>JSON (Download)</option>
                      </React.Fragment>
                    : null }
                  </MaterialNativeSelect>
                  </Grid>
                  <Grid container item xs={12} md={6} direction="column">
                  <MaterialNativeSelect
                    name="defaultValue"
                    label={"Fill Empty Cells with:"}
                    labelError={blank_text_error}
                  >
                    <option value={""}>Blank Field</option>
                    <option value={"null"}>Null</option>
                    <option value={"undefined"}>Undefined</option>
                  </MaterialNativeSelect>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
  
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={<Switch checked={advancedFilters} onChange={handleAdvancedFilters} />}
              label="Show Advanced Filters"
            />
            <Collapse in={advancedFilters}>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  className={classes.title}
                >
                  Advanced Filters
                </Typography>
                <Divider />
                {/*//console.log("RODOU")*/}
                <Paper className={fixedHeightPaper}>
                  <CountrySelectMult 
                      name={"FoodOrigin"}
                      label={"Select Food Origin"}
                      labelError={blank_text_error} />
                    <Divider />
                    <MaterialInput
                      name={"StudyID"}
                      label={"StudyID"}
                      type={"text"}
                      isrequired={false}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                    <Divider />
                  {
                  Object.entries(jsonResultsData).map(([key, value]) => {
                    return value.data == null 
                    ?
                    <React.Fragment key={key}>
                    <MaterialInput
                      name={key}
                      label={value.label}
                      type={value.type || "text"}
                      isrequired={false}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                    <Divider />
                    </React.Fragment>
                    :
                    <React.Fragment key={key}>
                    <MaterialMultNativeSelect
                      label={value.label}
                      name={key}
                      labelError={blank_text_error}
                      multiple
                      inputProps={{
                        style: {
                            padding: 10,
                        }
                      }}
                    >
                    <option value={""}>All</option>
                    {value.data.map((dataValue) =>
                      dataValue != "label" && dataValue != "selected" ? (
                        <option key={dataValue} value={dataValue}>
                          {dataValue}
                        </option >
                      ) : null
                    )}
                    </MaterialMultNativeSelect>
                    <Divider />
                    </React.Fragment>
                  })}
                </Paper>
            </Collapse>
          </Grid>

          <Grid item xs={12} md={12} container justify="flex-end" spacing={2}>
            <Grid item>
              <SubmitButton
                //onClick={() => form1Ref.current.submitForm()}
                isLoading={isLoading}
                text={"Search"}
              />
              </Grid>
              <Grid item>
              {appStore.isAllowed() ? 
                <Button
                  onClick={() => callBibDownloadAPI()}
                  variant="contained"
                  size="large"
                  disabled={rows.length == 0 ? true : false}
                  className={classes.customBTNBack}
                >
                  Download Bib
                </Button>
              : null}
              </Grid>
            </Grid>
      </Grid>
      </Form>

      <Grid item xs={12} md={12}>
      <TableVirtualized rows={rows} />
      </Grid>

    </Grid>

    <Snackbar open={openSnackAlert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})}>
        <Alert onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} severity={openSnackAlert.severity}>
          <AlertTitle>{openSnackAlert.title}</AlertTitle>
          {openSnackAlert.msg}
        </Alert>
    </Snackbar>
      
    </Grid>
  );
}
