// The default route is necessary for the correct application of the reverse proxy
const defaultRoute = "/PathogensInFood";

export default {
    // All the FrontEnd Routes used by system (mainly in Routes & Layout Folders)
    "routes": {
            "menu": {
                "default": defaultRoute + "/",
                "metaAnalysis": defaultRoute + "/menu/meta-analysis",
                "distributions": defaultRoute + "/menu/distributions",
                //"about": defaultRoute + "/about",    
            },
            "bacteria": {
                "new": defaultRoute + "/bacteria/new",
                "search": defaultRoute + "/bacteria/search",
                "searchLabel": defaultRoute + "/bacteria/search-label",
                "manage": defaultRoute + "/bacteria/manage",
                "manageEdit": defaultRoute + "/bacteria/manage/edit/:bacteria_id",
                "manageEditNoParam": defaultRoute + "/bacteria/manage/edit/",
            },
            "parasite": {
                "new": defaultRoute + "/parasite/new",
                "search": defaultRoute + "/parasite/search",
                "searchLabel": defaultRoute + "/parasite/search-label",
                "manage": defaultRoute + "/parasite/manage",
                "manageEdit": defaultRoute + "/parasite/manage/edit/:parasite_id",
                "manageEditNoParam": defaultRoute + "/parasite/manage/edit/",
            },
            "virus": {
                "new": defaultRoute + "/virus/new",
                "search": defaultRoute + "/virus/search",
                "searchLabel": defaultRoute + "/virus/search-label",
                "manage": defaultRoute + "/virus/manage/",
                "manageEdit": defaultRoute + "/virus/manage/edit/:virus_id",
                "manageEditNoParam": defaultRoute + "/virus/manage/edit/",
            },
            "users": {
                "manage": defaultRoute + "/users/manage",
            },
            "study": {
                "new": defaultRoute + "/study/new",
                "search": defaultRoute + "/study/search",
                "manage": defaultRoute + "",
            },
    }
}