import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from "@material-ui/core/Divider";

import Colors from "../../Styles/Colors";
import Texts from "../../Styles/Texts";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
      minWidth: '300px',
  },
  dialogSucess: {
      borderColor: Colors.sucessGreen2,
      borderWidth: 2,
      borderRadius: 15,
  },
  dialogError: {
    borderColor: Colors.errorRed2,
    borderWidth: 2,
    borderRadius: 15,
  },
  titleSucess: {
    color: Colors.sucessGreen1,
  },
  titleError: {
    color: Colors.errorRed1,
  },
  fontBlack: {
    color: Colors.black
  },
  fontWhite: {
    color: Colors.white  
  },
  customBTN: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
}));

export default function BacteriaNew({ open=false, type="normal", loading=false, title="", msg="", onClose }) {
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  // Type => 3 Themes
  // normal = Black Title
  // error = Red Title
  // sucess = Green Title
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={loading}
        classes={ {paper: classes.dialogPaper} }
      >
        <DialogTitle id="alert-dialog-title" className={ type == "normal" ? classes.fontBlack : type == "error" ? classes.titleError : classes.titleSucess } >{title}</DialogTitle>
        <Divider />
        <DialogContent style={{marginTop: "15px"}}>
          {loading ? <CircularProgress /> : null}
          <DialogContentText id="alert-dialog-description" className={classes.fontBlack}>
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}