export default {
    white: "#ffffff",
    black: "#000000",

    backgroundColor: "#EEEEEE",

    grayUltraLight: "#fcfcfc",
    grayLight: "#eeeeee",
    grayMedium: "#D3D3D3",
    grayDark: "#696969",
    grayHigh: "#3b3b3b",

    transparentBlack: "rgba(0,0,0,0.2)",
    transparentWhite: "rgba(255,255,255,0.2)",

    transparentBlack01: "rgba(0,0,0,0.1)",
    transparentWhite01: "rgba(255,255,255,0.1)",

    transparentBlack005: "rgba(0,0,0,0.05)",
    transparentWhite005: "rgba(255,255,255,0.05)",

    primaryUltraLight: "#87c4f5",
    primaryLight: "#51adf6",
    primary: "#2196f3",
    primaryDark: "#0a6ebd",
    primaryDarkHigh: "#044a82",
    primaryUltraDark: "#024173",

    secondaryUltraLight: "#b1fdff",
    secondaryLight: "#00faff",
    secondary: "#00cee6",
    secondaryDark: "#00a0b3",
    secondaryUltraDark: "#007280",

    thirdUltraLight: "#b1fffa",
    thirdLight: "#00ffee",
    third: "#00ccbe",
    thirdDark: "#00b3a7",

    errorDefault: "#f00",

    fourth: "#A546B6",

    sucessGreen1: "#006400",
    sucessGreen2: "#9cfc95",
    errorRed1: "#8b0000",
    errorRed2: "#ffc9c9",
  };