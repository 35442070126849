import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function SnackAlert({open, severity, title, msg, ...props}) {
    return (
        <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} {...props}>
            <Alert  {...props} severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                {msg}
            </Alert>
        </Snackbar>
    )
};