import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import api from '../../../Services/api';

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import {
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Box,
  FormLabel,
  Snackbar,
} from "@material-ui/core";

import MuiAlert from '@material-ui/lab/Alert';
import SendIcon from '@material-ui/icons/Send';

import MaterialInput from "../../../Components/Inputs/MaterialInput";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";
import CustomDialog from "../../../Components/Dialog/CustomDialog";

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";
import Skeleton from '@material-ui/lab/Skeleton';

import * as Yup from 'yup';

import TableVirtualized from "../../../Components/Tables/TableVirtualized";
import TableMaterial from "../../../Components/Tables/TableMaterial";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5NzA2OTQwMiwiZXhwIjoxNTk3MzI4NjAyfQ.Ya6a6vW1fKLu0lhQom37XlJ9KeZfEJECWAkDbLJ5gaM";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: 'bold',
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTN: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
  input: {
    display: 'none',
  },
}));

export default function BacteriaNew() {
  const blank_text = Texts.leave_blank;
  const required_text = Texts.required;
  const blank_text_error = Texts.blank_error;
  
  const formType0Ref = useRef(null);
  const formType1Ref = useRef(null);

  const [rawFile, setRawFile] = useState(null);
  const [workFile, setWorkFile] = useState({});

  const [dialogData,setDialogData] = useState({
    open: false,
    type: "normal",
    loading: false,
    title: "",
    msg: "",
  });

  const handleCloseDialog = (value) => {
    setDialogData({...dialogData, open:false});
  };

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  // Tree & General Info
  const [dataGeneralInfo, setDataGeneralInfo] = useState(null);

  const [registerType,setRegisterType] = useState("0");
  const [openSnackAlert,setOpenSnackAlert] = useState(false);

  
  const [rowsCreated,setRowsCreated] = useState([]);
  const [rowsUpdated,setRowsUpdated] = useState([]);
  const [rowsErrors,setrowsErrors] = useState([]);


  const callStudyAPI = (attempt) => {
    //console.log("Running API - Attempt ",attempt);

    if (attempt >= 1) {
      setDialogData({
        open: true,
        type: "error",
        loading: false,
        title: "Error",
        msg: Texts.dialog_errors.max_attempts,
      });

      setDataGeneralInfo({});
      return ;
    }

    if (dataGeneralInfo == null) {
      // Run API Call
        api.get('/study/struct/all').then(response => {
            //console.log("recebeu:", response.data);
            if (!response.data) {
                //console.log("entrou aqui");
                //setMsg("Não foram encontrados vendedores dessa categoria em sua região.");
            }
            setDataGeneralInfo(response.data);
            return;
        }).catch(err => {
            callStudyAPI(attempt+1);
            ////console.log(err);
            //setNotFound(true);
            //setMsg("Não foi possível realizar a comunicação com o servidor. Tente novamente!");
        });
    }
  }

  useEffect(() => {
    callStudyAPI(0);
  },[]);

  async function handleSubmitFormType0(data, { reset }) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = formType0Ref.current.getFieldRef(value).current || formType0Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formType0Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            formType0Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (formType0Ref.current.getFieldRef(value).required) {
              formType0Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            formType0Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      callRegisterAPI(data);
    } else {
      setOpenSnackAlert(true);
    }
  }

  const callRegisterAPI2 = (data) => {
    setDialogData({
      open: true,
      loading: true,
      type: "normal",
      title: "Registering...",
      msg: "",
    });

    api.post('/study/new/bib', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      //console.log("recebeu:", response.data);

      let CreatedIds = ["StudyID"];
      let UpdateIds = ["StudyID"];
      let ErrorsIds = ["StudyID"];

      response.data.created.map(data => {
        return CreatedIds.push(data.StudyID);
      });

      response.data.updated.map(data => {
        return UpdateIds.push(data.StudyID);
      });

      response.data.errors.map(data => {
        return ErrorsIds.push(data.StudyID);
      })

      if (CreatedIds.length > 1) {
        setRowsCreated(CreatedIds);
      } else {
        setRowsCreated([]);
      }

      if (UpdateIds.length > 1) {
        setRowsUpdated(UpdateIds);
      } else {
        setRowsUpdated([]);
      }
      
      if (ErrorsIds.length > 1) {
        setrowsErrors(ErrorsIds);
      } else {
        setrowsErrors([]);
      }


      setDialogData({
        open: true,
        type: "sucess",
        loading: false,
        title: "Sucess",
        msg: Texts.page_study_new.dialog_sucess_register,
      });
      return;
    }).catch(function (error) {
      let exists = false;
      try {
        if ('response' in error && 'data' in error.response && 'error' in error.response.data) {
              exists = true;
        }       
      } catch {
        exists = false;
      }

     setDialogData({
        open: true,
        loading: false,
        type: "error",
        title: "Error",
        msg: exists ? error.response.data.error : Texts.page_study_new.dialog_error_register,
      });
    });
  }

  const callRegisterAPI = (data) => {
    setDialogData({
      open: true,
      loading: true,
      type: "normal",
      title: "Registering...",
      msg: "",
    });

    api.post('/study/new', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      //console.log("recebeu:", response.data);

      setDialogData({
        open: true,
        type: "sucess",
        loading: false,
        title: "Sucess",
        msg: Texts.page_study_new.dialog_sucess_register,
      });
      return;
    }).catch(function (error) {
      let exists = false;
      try {
        if ('response' in error && 'data' in error.response && 'error' in error.response.data) {
              exists = true;
        }       
      } catch {
        exists = false;
      }

     setDialogData({
        open: true,
        loading: false,
        type: "error",
        title: "Error",
        msg: exists ? error.response.data.error : Texts.page_study_new.dialog_error_register,
      });
    });
  }

  async function handleSubmitFormType1(data, { reset }) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = formType1Ref.current.getFieldRef(value).current || formType1Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formType1Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            formType1Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (formType1Ref.current.getFieldRef(value).required) {
              formType1Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            formType1Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      callRegisterAPI(data);
    } else {
      setOpenSnackAlert(true);
    }
  }

  const handleRegisterType = (event) => {
    setRegisterType(event.target.value);
  }

  const handleFileVariables2 = () => {
    let raw = rawFile;
    ////console.log(rawFile);
    callRegisterAPI2({file: rawFile});
  }

  const handleFileVariables = () => {
    
    let newWorkFile = {};

    let raw = rawFile;
    // SPLIT BIB Line by Line (Each line have attribute = value)
    let rawLine = raw.split(/\n/);
    

    if (rawLine[0].includes('@')) {
      let startIndex = rawLine[0].indexOf('{') + 1;
      let endIndex = rawLine[0].length;
      if (rawLine[0][rawLine[0].length-1] == ",") {
        endIndex = rawLine[0].length - 1;
      }
      newWorkFile["StudyID"] = rawLine[0].substr(startIndex,endIndex);
    }

    for (let i in rawLine) {
      if (rawLine[i].includes('=')) {
        // Split VARNAME and VARVALUE from BIB Line
        let varValue = rawLine[i].split(/=(.+)/)[1];
        let varName = rawLine[i].split('=')[0].trim();  // Remove Spaces from VARNAME

        // Check if Value is inside { }
        if (varValue.includes("{") && varValue.includes("}")) {
          // Remove the First "{" from VARVALUE
          varValue = varValue.split(/{(.+)/)[1];

          // Remove the Last "}" from VARVALUE
          varValue = varValue.split("").reverse().join("");
          varValue = varValue.split(/}(.+)/)[1];
          varValue = varValue.split("").reverse().join("");
        }

        //console.log("[",varName,"] ",varValue);
        newWorkFile[varName] = varValue;        
      }
    }
    setWorkFile(newWorkFile);
  }

  const handleFileChosen = (file) => {
    setWorkFile({});
    
    if (file === undefined || file === null) {
      setRawFile(null);
      return null;
    }
    
    let fileReader = new FileReader();
    fileReader.onloadend = (read) => {
      //this.setState({filetext : read.result});
      //console.log(read.currentTarget.result);
      setRawFile(read.currentTarget.result);
    }
    fileReader.readAsText(file);
  }

  return (
    dataGeneralInfo == null
    ? <Skeleton variant="rect" width={300} height={250} />
    :
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Register Type</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
          <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup row aria-label="essayType" name="registerType" value={registerType} onChange={handleRegisterType}>
            <FormControlLabel value="0" selected control={<Radio />} label="From Bib" />
            <FormControlLabel value="1" control={<Radio />} label="Manual Entry" />
          </RadioGroup>
          </FormControl>
        </Paper>
      </Grid>
      {registerType === "0" 
      ?
      <>

      <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Upload File (only bib/txt)</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        <Grid container>
        <div>
          <input
            accept=".bib,.txt"
            className={classes.input}
            id="contained-button-file"
            name="bibUpload"
            type="file"
            onChange={e => handleFileChosen(e.target.files[0])}
          />
          <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="default"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload
          </Button>
          </label>
        </div>
        <Box ml={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            disabled={rawFile === null ? true : false}
            onClick={handleFileVariables2}
          >
          Read File
          </Button>
        </Box>
        </Grid>
        </Paper>
      </Grid>
      
      <Grid item xs={12} md={12}>
      <Form ref={formType1Ref} className={classes.formWide} onSubmit={handleSubmitFormType1}>

      {Object.keys(workFile).length > 0 
      ? <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Study Info</Typography>
      <Divider />
      <Paper className={fixedHeightPaper}>
      { 
          Object.entries(workFile).map(([key, value]) => {
            return (
              <MaterialInput
              key={key}
              name={key}
              label={key + required_text}
              type={"text"}
              defaultValue={value}
              isrequired={true}
              labelError={blank_text_error}
              placeholder={"enter text..."}
              />
          )
      })}
      </Paper>
      </Grid>

      <Grid item xs={12} md={12} container justify="flex-end">
      <Button
        onClick={() => formType1Ref.current.submitForm()}
        variant="contained"
        size="large"
        className={classes.customBTN}
      >
        Register
      </Button>
      </Grid>
      </Grid>

      : null}

      </Form>

      <Grid container  spacing={3} direction="row">
        <Grid item xs={4} md={4}>
          <Typography variant="h6">Created Studies:</Typography>
          <TableMaterial rows={rowsCreated} tableHeight={200} />
          <Typography variant="subtitle1" color="textSecondary">Total Items: {rowsCreated.length == 0 ? 0 : rowsCreated.length-1}</Typography>
        </Grid>
        <Grid item xs={4} md={4}>
        <Typography variant="h6">Updated Studies:</Typography>
          <TableMaterial rows={rowsUpdated} tableHeight={200} />
          <Typography  variant="subtitle1" color="textSecondary">Total Items: {rowsUpdated.length == 0 ? 0 : rowsUpdated.length-1}</Typography>
        </Grid>
        <Grid item xs={4} md={4}>
        <Typography variant="h6">Errors:</Typography>
          <TableMaterial rows={rowsErrors} tableHeight={200} />
          <Typography  variant="subtitle1" color="textSecondary">Total Items: {rowsErrors.length == 0 ? 0 : rowsErrors.length-1}</Typography>
        </Grid>
      </Grid>

      </Grid>

      </>

      : null }

      {registerType === "1"
      ? 
      <Grid item xs={12} md={12}>
      <Form ref={formType0Ref} className={classes.formWide} onSubmit={handleSubmitFormType0}>
      <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Study Info
            </Typography>
            <Divider />
            {/*//console.log("RODOU")*/}
            <Paper className={fixedHeightPaper}>
            { 
              Object.entries(dataGeneralInfo).map(([key, value]) => {
                return value.data == null 
                ?
              <MaterialInput
                key={key}
                name={key}
                label={value.label + (value.required ? required_text : blank_text)}
                type={value.type || "text"}
                isrequired={value.required}
                labelError={blank_text_error}
                placeholder={"enter text..."}
              />
              :
              <MaterialNativeSelect
              key={key}
              label={value.label + (value.required ? required_text : blank_text)}
              defaultValue={""}
              labelError={blank_text_error}
              name={key}
              >
              <option value={""}>Select</option >
              {value.data.map((dataValue) =>
                dataValue != "label" && dataValue != "selected" ? (
                  <option key={dataValue} value={dataValue}>
                    {dataValue}
                  </option >
                ) : null
              )}
              </MaterialNativeSelect>
            })}
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => formType0Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTN}
              >
                Register
              </Button>
            </Grid>
      </Grid>
      </Form>
      </Grid>
      : null}

      <CustomDialog open={dialogData.open} type={dialogData.type} loading={dialogData.loading} title={dialogData.title} msg={dialogData.msg} onClose={handleCloseDialog} />

      <Snackbar open={openSnackAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert(false)}>
        <Alert onClose={() => setOpenSnackAlert(false)} severity="error">
          ERROR: Check that all required fields have been completed.
        </Alert>
      </Snackbar>

    </Grid>
  );
}