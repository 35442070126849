import React, { useEffect, useState } from 'react';
import { restAPI } from '../../Services/api';
import CustomSelect from '../../Components/Selects/CustomSelect';

export default function CountrySelect({defaultValue, label, name, text_error}) {
    
    const [data,setData] = useState([]);

    // Run Just one time & fill component with country names
    useEffect(() => {
        restAPI.gets().getCountriesNames().then(response => {
            setData(response.data.countries);
        }).catch(error => {

        });
    }, [])

    return (
        <CustomSelect
            label={label || "Food Origin"}
            defaultValue={defaultValue}
            name={name || "FoodOrigin"}
            items={data}
            labelError={text_error || ""}
            clearable={true}
      />
    );
}