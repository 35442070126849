import React, {useState} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Grid as VirtualizedGrid,
} from "react-virtualized";
import TableCell from "@material-ui/core/TableCell";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Colors from "../../Styles/Colors";
import Texts from "../../Styles/Texts";

const useStyles = makeStyles((theme) => ({
    cellHeader: {
      color: Colors.black,
      backgroundColor: Colors.primaryUltraLight,
      display: 'flex',
      alignItems: 'center'
    },
    cellRow: {
      color: Colors.grayHigh,
    },
    cellRowSelected: {
      backgroundColor: Colors.secondaryUltraLight
    }
}));

export default function TableVirtualized({rows=[], showCounter=true, tableHeight=500}) {
    const classes = useStyles();
    const [rowSelected,setRowSelected] = useState(null);

  function cellRenderer({ columnIndex, key, parent, rowIndex, style }) {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <TableCell
          component={"span"}
          style={style}
          onClick={(e) => rowIndex === 0 ? setRowSelected(null) : setRowSelected(rowIndex)}
          className={rowIndex == 0 ? classes.cellHeader : rowIndex === rowSelected ? classes.cellRowSelected : classes.cellRow}
        >
          {rows[rowIndex][columnIndex]}
        </TableCell>
      </CellMeasurer>
    );
  }

  const cache = new CellMeasurerCache({
    defaultWidth: 100,
    minWidth: 75,
    fixedHeight: false,
    minHeight: 20,
  });

  return (
    <div>
    <Typography variant="h6"
        color="inherit"
        noWrap className={classes.contResultsLabel}>Results Found: {rows.length == 0 ? 0 : rows.length-1}</Typography>
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedGrid
          style={{ backgroundColor: Colors.white, borderRadius: "5px" }}
          cellRenderer={cellRenderer}
          columnCount={rows.length == 0 ? 0 : rows[0].length}
          columnWidth={cache.columnWidth}
          height={tableHeight}
          rowCount={rows.length}
          rowHeight={cache.rowHeight}
          width={width}
        />
      )}
    </AutoSizer>
    </div>
  );
}
