import React from "react";
//import useStores from "../stores/useStores";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Box, Paper, Grid, Divider } from "@material-ui/core";

import Colors from "../../../Styles/Colors";
import logo_pathogens from "./LogoPathogens.png";
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  cardImg2: {
    width: '40%',
  },
  paper2: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayHigh,
  }
}));

function About() {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Paper className={classes.paper2}>
        <CardMedia
            className={classes.cardImg2}
            image={logo_pathogens}
            component="img"
            title="Logo"
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Box mt={2} ml={2}>
            <Box mb={2}>
            <Typography variant="h4">ABOUT</Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography>
                Pathogens in Foods is the result of the effort and support of:
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <b>
                  The French Agency for Food, Environmental and Occupational
                  Health & Safety
                </b>
              </Typography>
              <Typography>Contacts: Dr. Pauline Kooh, Dr. Moez Sanaa</Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <b>
                  CIMO Mountain Research Centre, Polytechnic Institute of
                  Bragança, Portugal
                </b>
              </Typography>
              <Typography>
                Contacts: Dr. Ursula Gonzales-Barron, Dr. Vasco Cadavez
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                If you wish to donate data, please contact Dr. Ursula
                Gonzales-Barron (E-mail: <a href="mailto:ubarron@ipb.pt" target="blank">ubarron@ipb.pt</a>)
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default observer(About);
