import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import CountrySelect from "../../../Components/CountrySelect";

import {
  useParams
} from "react-router-dom";

//import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import api from '../../../Services/api';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import Button from "@material-ui/core/Button";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MaterialInput from "../../../Components/Inputs/MaterialInput";
//import MaterialSelect from "../../../Components/Selects/MaterialSelect";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CustomSelect from "../../../Components/Selects/CustomSelect";

import CustomAutoComplete from "../../../Components/Selects/CustomAutoComplete";

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";

import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  dividerHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disabledHeader: {
    backgroundColor: Colors.grayMedium,
  },
  expandedHeader: {
    
  },
  doneStepIcon: {
    color: Colors.primaryDarkHigh
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: 'bold',
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTNBack: {
    backgroundColor: Colors.grayDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "100px",
    "&:hover": {
      backgroundColor: Colors.grayHigh,
    },
  },
  customBTNNextRegister: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BacteriaEdit() {
  const blank_text = Texts.leave_blank;
  const required_text = Texts.required;
  const blank_text_error = Texts.blank_error;

  const { bacteria_id } = useParams();

  const form1Ref = useRef(null);
  const form2Ref = useRef(null);
  const form3Ref = useRef(null);
  const formStudyRef = useRef(null);

  const formRef = useRef(null);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [formData,setFormData] = useState({
    form1: null,
    form2: null,
    form3: null
  });

  // APIs JSON Data
  const [jsonstudies, setJsonStudies] = useState([]); // Study IDs
  const [jsonStudyStruct,setJsonStudyStruct] = useState([]); // Study Struct

  const [jsonFoods, setJsonFoods] = useState({}); // Food Info [TREE]
  const [jsonFoodCharacs,setJsonFoodCharacs] = useState({}); // Food Chacaracteristics

  const [jsonAgent,setJsonAgent] = useState({}); // Agent Info [TREE]
  const [jsonAgentData,setJsonAgentData] = useState({}) // Agent Info
  const [jsonResultsData,setjsonResultsData] = useState({}); // Results Info
  const [jsonPrevalenceData,setjsonPrevalenceData] = useState({});
  const [jsonCountData,setJsonCountData] = useState({});
  const [jsonFullStruct,setJsonFullStruct] = useState({});

  const [openSnackAlert,setOpenSnackAlert] = useState({
    open: false,
    severity: "info",
    title: "",
    msg: "",
  });

  // Which Form will be expanded in Page -> 1: Form1 - 2: Form2 - 3: Form3
  const [formExpanded, setFormExpanded] = useState(1); 

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogStudyOpen, setDialogStudyOpen] = useState(false);
  const [dialogStudyData,setDialogStudyData] = useState({});

  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // 0: Prevalence  | 1: Count | 2: Both
  const [essayType, setEssayType] = useState(-1);

  // State of Full Structure (for POST the final data in database)
  const [dataFullStruct,setDataFullStruct] = useState({});

  // Tree & State of FOODS
  const [data, setData] = useState(null);
  const [dataAux, setDataAux] = useState([]);

  // Tree & State of AGENTS
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Tree & State of PREVALENCE
  const [jsonEssayPrevalence, setJsonEssayPrevalence] = useState(null);
  const [dataPrevalenceEssay, setDataPrevalenceEssay] = useState(null);
  const [dataAuxEssayPrevalence, setDataAuxEssayPrevalence] = useState([]);

  const [bacteriaData,setBacteriaData] = useState({});
  const [bacteriaDataAux,setBacteriaDataAux] = useState({});

  // Tree & States of COUNT
  const [jsonEssayCount, setJsonEssayCount] = useState(null);
  const [dataCountEssay, setDataCountEssay] = useState(null);
  const [dataAuxEssayCount, setDataAuxEssayCount] = useState([]);

  const [runOnce,setRunOnce] = useState(false);

  const [isReadyToEdit,setIsReadyToEdit] = useState(false);

  const [runOnceEdit,setRunOnceEdit] = useState({
    countEssay: false,
    prevalenceEssay: false,
    foodTree:  false,
  });

  useEffect(() => {
    if (!runOnce) {
      ////console.log("ID = ",bacteria_id);
      if (bacteria_id == null || bacteria_id == undefined) {
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: "Invalid Bacteria ID - Edit Error"
        });
          
      } else {
          callStudyIdAPI(0);
          callFoodAPI(0);
          callBacteriaAPI(0);
          callStudyStructAPI(0);
          callEditDataAPI(0);
      }
      setRunOnce(true);
    }
  },[]);

  const typeBac = () => {
    if ("Prevalence" in bacteriaData && "Count" in bacteriaData) {
      return "2"
    } else if ("Count" in bacteriaData) {
      return "1"
    } else if ("Prevalence" in bacteriaData) {
      return "0"
    } else {
      return ""
    }
  }

  useEffect( () => {
    if (Object.keys(jsonAgent).length > 0) {
      handleAgent(bacteriaData["Bacterium"]);

      if (Object.keys(bacteriaData).length > 0) {
        handleEssayType(typeBac());
      }
    }
  },[jsonAgent,bacteriaData]);

  useEffect( () => {
    if (!runOnceEdit.prevalenceEssay) {
      if (dataAuxEssayPrevalence.length > 0 && Object.keys(bacteriaData).length > 0) {
        setRunOnceEdit({...runOnceEdit, prevalenceEssay: true});
        if ("Prevalence" in bacteriaData) {
          dataAuxEssayPrevalence.map((json,index) => {
            if ([json.label] in bacteriaData.Prevalence) {
              return handleEssayPrevalence( json.label, index, bacteriaData.Prevalence[json.label] ); 
            }
          })
        }
      }
    }
  },[bacteriaData,dataAuxEssayPrevalence]);

  useEffect( () => {
    if (!runOnceEdit.countEssay) {
      if (dataAuxEssayCount.length > 0 && Object.keys(bacteriaData).length > 0) {
        setRunOnceEdit({...runOnceEdit, countEssay: true});
        if ("Count" in bacteriaData) {
          dataAuxEssayCount.map((json,index) => {
            if ([json.label] in bacteriaData.Count) {
              return handleEssayCount( json.label, index, bacteriaData.Count[json.label] ); 
            }
          })
        }
      }
    }
  },[bacteriaData,dataAuxEssayCount]);

  /*useEffect( () => {
    //onChange={(event) => handleChange(json.label,index,event.target.value)}
    //defaultValue={ [json.label] in bacteriaData ? bacteriaData[json.label] : "" }
    if (!runOnceEdit.foodTree) {
      if (dataAux.length > 0 && Object.keys(bacteriaData).length > 0) {
        setRunOnceEdit({...runOnceEdit, foodTree: true});
        dataAux.map((json,index) => {
          if ([json.label] in bacteriaData) {
            return handleChange( json.label, index, bacteriaData[json.label] ); 
          }
        });
      }
    }
  },[bacteriaData,dataAux]);*/

  /*useEffect( () => {
    if (!runOnceEdit.foodTree) {
      if (dataAux.length > 0 && Object.keys(bacteriaData).length > 0) {
        //console.log("ENTROU");
        let alreadyHave = false;
        let bacData = bacteriaData;
        dataAux.map((json,index) => {
          if ([json.label] in bacteriaData) {
            alreadyHave = true;
            delete bacData[json.label];
            handleChange( json.label, index, bacteriaData[json.label] );
            return setBacteriaData(bacData);
          }
        });
        if (alreadyHave == false) {
          //console.log("PAROU");
          setRunOnceEdit({...runOnceEdit, foodTree: true});
        }
      }
    }
  },[bacteriaData,dataAux]);*/

  useEffect( () => {
    if (!runOnceEdit.foodTree) {
      if (dataAux.length > 0 && Object.keys(bacteriaDataAux).length > 0) {
        let bacData = cloneDeep(bacteriaDataAux);
        let alreadyHave = false;
        dataAux.map((json,index) => {
          if ([json.label] in bacteriaDataAux) {
            
            alreadyHave = true;
            handleChange( json.label, index, bacData[json.label] ); 
            delete bacData[json.label];
            return setBacteriaDataAux(bacData);
          }
        });
        if (alreadyHave == false) {
          
          setRunOnceEdit({...runOnceEdit, foodTree: true});
        }
      }
    }
  },[bacteriaData,dataAux, bacteriaDataAux]);

  const setDialogErrorMaxAttempts = (msg=null) => {
    setOpenSnackAlert({
      open: true,
      severity: "error",
      title: "Error",
      msg: msg==null ? Texts.dialog_errors.max_attempts : msg
    });
  }

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const fillEditForm = async (data) => {
    //console.log("Received:",data);
    // form1Ref.current.getFieldValue("StudyID");
    // form1Ref.current.getFieldRef("StudyID");
    
    setOpenSnackAlert({
      open: true,
      severity: "info",
      title: "LOADING",
      msg: "Loading Data..."
    });

    await timeout(3000); //for 1 sec delay
    ////console.log("Startou");
    // Fill Tree Selects
    
    //await timeout(3000);

    setIsReadyToEdit(true);
  }

  const callEditDataAPI = (attempt) => {
    api
      .get("/bacteria/manage/edit", {params: {id: bacteria_id}, headers: {authorization: authToken}})
      .then((response) => {
        setBacteriaData(response.data);
        setBacteriaDataAux(response.data);
        
        ////console.log("CHAMOU ",attempt);
        return fillEditForm(response.data);
      }).catch(err => {
        ////console.log("CHAMOU ERRO ",attempt);
        if (attempt >= 2) {
          try {
            return setDialogErrorMaxAttempts(err.response.data.error);
          } catch {
            return setDialogErrorMaxAttempts();
          }
        } else {
          callEditDataAPI(attempt+1);
        }
      });
  }

  const callBacteriaAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonAgent({});
      setJsonAgentData({});
      setjsonResultsData({});
      setjsonPrevalenceData({});
      setJsonCountData({});
      return ;
    }

    // Run API Call
    api.get('/bacteria/struct/all').then(response => {
      ////console.log("recebeu:", response.data);
      if (!response.data) {
          ////console.log("entrou aqui");
      }
      setJsonAgent(response.data.bacteria_tree);
      setJsonAgentData(response.data.bacteria_data);
      setjsonResultsData(response.data.results_data);
      setjsonPrevalenceData(response.data.prevalence_data);
      setJsonCountData(response.data.count_data);
      setJsonFullStruct(response.data.full_structure);
      setDataFullStruct(response.data.full_structure);
      return ;
    }).catch(err => {
      callBacteriaAPI(attempt+1);
    });

  };

  const callFoodAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setDataAux([]);
      setJsonFoods(null);
      setJsonFoodCharacs({});
      return ;
    }
  
    // Run API Call
    api.get('/food/struct/all').then(response => {
        ////console.log("recebeu:", response.data);
        if (!response.data) {
            ////console.log("entrou aqui");
        }
        setJsonFoods(response.data.food_tree);
        setDataAux([response.data.food_tree]);
        setJsonFoodCharacs(response.data.food_data);
        //setJsonFoods(response.data.food_data);
        return ;
    }).catch(err => {
        callFoodAPI(attempt+1);
    });
  };

  const callStudyIdAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudies([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/list/id_only').then(response => {
        ////console.log("recebeu:", response.data);
        if (!response.data) {
            ////console.log("entrou aqui");
        }
        setJsonStudies(response.data);
        return ;
    }).catch(err => {
        callStudyIdAPI(attempt+1);
    });
  }

  const callStudyStructAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudyStruct([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/struct/study_data').then(response => {
        ////console.log("recebeu:", response.data);
        if (!response.data) {
            ////console.log("entrou aqui");
        }
        setJsonStudyStruct(response.data);
        return ;
    }).catch(err => {
        callStudyStructAPI(attempt+1);
    });
  }

  const callGetStudyInfoAPI = () => {
    try {
      let getStudyID = form1Ref.current.getFieldValue("StudyID");
      if (getStudyID == "") {
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: "No StudyID selected"
        });
      } else {
        // Run API Call
        api.get('/study/list/id',{params: {StudyID: getStudyID}, headers: {authorization: authToken}})
        .then((response) => {
          ////console.log("recebeu:", response.data);
          setDialogStudyData(response.data);
          setDialogStudyOpen(true);
          return ;
        }).catch(err => {
  
        });
      }
    } catch (err) {
      ////console.log(err);
    }
  }

  const handleAgent = (event) => {
    ////console.log("Entrou EVENT: ",event);

    try {
      if (event == "null" || event == "") {
        setEssayType(-1);
        setSelectedAgent(null);
  
        setJsonEssayCount(null);
        setDataAuxEssayCount([null]);
  
        setJsonEssayPrevalence(null);
        setDataAuxEssayPrevalence([null]);
  
      } else {
        setJsonEssayCount(jsonAgent[event].count);
        setDataAuxEssayCount([jsonAgent[event].count]);
  
        setJsonEssayPrevalence(jsonAgent[event].prevalence);
        setDataAuxEssayPrevalence([jsonAgent[event].prevalence]);
  
        setSelectedAgent(event);
      }
    } catch (err) {
      ////console.log("----");
      ////console.log(jsonAgent[event]);
      ////console.log(jsonAgent);
      ////console.log("ERRO GRAVE: ",err);
    }
  };

  const removeNullFields = (obj) => {
    Object.entries(obj).forEach(([key, val])  =>
      (val && typeof val === 'object') && removeNullFields(val) ||
      (val === null || val === "") && delete obj[key]
    );
    return obj;
  };

  async function handleSubmitFormStudy(data, {reset}, event) {
    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = formStudyRef.current.getFieldRef(value).current || formStudyRef.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formStudyRef.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (formStudyRef.current.getFieldRef(value).required) {
              formStudyRef.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      callUpdateStudyAPI(data);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }
  
  async function handleSubmitForm1(data, { reset }, event) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = form1Ref.current.getFieldRef(value).current || form1Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form1Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form1Ref.current.getFieldRef(value).required) {
              form1Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      // CONTINUAR DAQUI EM DIANTE *****************************************************************************
      /*let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(data).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = data[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = data[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = data[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);

      let finalData =  { ...data_formatted }
      ////console.log("Final:",finalData);
      */
      // Set Form1 Data
      setFormData({...formData, form1: data });
      // Open Form2
      setFormExpanded(2);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm2(data, { reset }, event) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form2Ref.current.getFieldRef(value).current || form2Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form2Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form2Ref.current.getFieldRef(value).required) {
              form2Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      // Set Form2 Data
      /*let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(data).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = data[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = data[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = data[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);

      let finalData =  { ...formData.form1, ...data_formatted }
      ////console.log("Final:",finalData);
      */
      setFormData({...formData, form2: data });
      // Open Form3
      setFormExpanded(3);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm3(data, { reset }, event) {
    ////console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form3Ref.current.getFieldRef(value).current || form3Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form3Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form3Ref.current.getFieldRef(value).required) {
              form3Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      let finalData =  cloneDeep({...formData.form1, ...formData.form2, ...data });
      let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(finalData).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = finalData[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = finalData[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = finalData[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);
      
      // Set Form3 Data
      setFormData({...formData, form3: data });
      // Call Backend For Register
      //setFormExpanded(3);
      //let finalData = prepareStudyJson(formData.form1, formData.form2, data);
      
      ////console.log("Final:",data_formatted);
      if (Object.keys(data_formatted['Prevalence']).length === 0) {
        delete data_formatted['Prevalence'];
      } else if (Object.keys(data_formatted['Count']).length === 0) {
        delete data_formatted['Count'];
      }
      ////console.log("Final:",data_formatted);
      data_formatted = {"_id": bacteriaData["_id"], ...data_formatted,  }

      //console.log(data_formatted);
      callEditAPI( data_formatted );

      setOpenSnackAlert({...openSnackAlert, msg: "Loading..."});
      setDialogOpen(true);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  const prepareStudyJson = (form1,form2,form3) => {
    let finalData = {};
    
    for (let key in form1) {
      if(form1.hasOwnProperty(key)){
        finalData[key] = form1[key];
      }
    }

    if (finalData.essay === "0") {
      finalData.essay = "prevalence";
    } else if (finalData.essay === "1") {
      finalData.essay = "count";
    } else {
      finalData.essay = "both";
    }

    for (let key in form2) {
      if(form2.hasOwnProperty(key)){
        finalData[key] = form2[key];
      }
    }

    for (let key in form3) {
      if(form3.hasOwnProperty(key)){
        finalData[key] = form3[key];
      }
    }
    
    return finalData;
  }

  const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5NzA2OTQwMiwiZXhwIjoxNTk3MzI4NjAyfQ.Ya6a6vW1fKLu0lhQom37XlJ9KeZfEJECWAkDbLJ5gaM";


  const callUpdateStudyAPI = (data) => {
    api.post('/study/manage/update', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      ////console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.update_sucess
      });

      return setDialogStudyOpen(false);

    }).catch(function (error) {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.update_error
      });
    });
  }

  const callEditAPI = (data) => {

    api.post('/bacteria/manage/edit', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      ////console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.update_sucess
      });

      setFormExpanded(0);
      return setIsReadyToEdit(false);

    }).catch(function (error) {
      ////console.log("ERROR");

      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.update_error
      });

    });
  }
  

  const handleBackForm2 = () => {
    setFormData({...formData, form1: null, form2: null });
    // Open Form2
    setFormExpanded(1);
  }

  const handleBackForm3 = () => {
    setFormData({...formData, form2: null, form3: null });
    // Open Form2
    setFormExpanded(2);
  }
  
  async function handleSubmit(data, { reset }, event) {
    //////console.log(Yup.object());
    //event.preventDefault();
    //event.stopPropagation();
    //event.nativeEvent.stopImmediatePropagation();

    ////console.log(data);

    let resErrors = {}
    Object.keys(data).map((value,index) => {
        //////console.log(value,"->",formRef.current.getFieldRef(value));
        //////console.log(typeof formRef.current.getFieldRef(value));
        //////console.log(value,"->",);
        //////console.log("-----");

        let isTextField = formRef.current.getFieldRef(value).current || formRef.current.getFieldRef(value).props || true;
        //////console.log(value," -> ",isTextField);

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            //////console.log(formRef.current);
          }
        } else {
          if (data[value] === "") {
            if (formRef.current.getFieldRef(value).required) {
              formRef.current.setFieldError(value, true);
              resErrors[value] = Yup.string().required();
            }
          } else {
            formRef.current.setFieldError(value, false);
            //////console.log(formRef.current);
          }
        }
        

       /* if (typeof isTextField != 'boolean') {
          if (data[value] == "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            //////console.log(formRef.current);
          }
        } else {
        }*/
    });
  }

  const handleChange = (label, index, event) => {
    //////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setData({ ...data, [label]: event });

      if (dataAux[index][event] != null) {
        let newData = dataAux;
        newData = newData.slice(0, index + 1);

        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";

        setDataAux(newData);
        /*} else {
            ////console.log("Tentou Alterar");
          }*/
      } else {
        let newData = dataAux;
        //////console.log("Entrada: ",newData);
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        //////console.log("Saida: ",newData);
        setDataAux(newData);
      }
    } else {
      //////console.log("entrou1")
      let newData = dataAux;
      //////console.log("Entrada: ",newData);
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      //////console.log("Saida: ",newData);
      setDataAux(newData);
    }
  };

  const handleEssayType = (event) => {
    if (event === "") {
      setEssayType(null);
    } else {
      setEssayType(event);
    }
  };

  const handleEssayPrevalence = (label, index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setDataPrevalenceEssay({ ...dataPrevalenceEssay, [label]: event });
      if (dataAuxEssayPrevalence[index][event] != null) {
        let newData = dataAuxEssayPrevalence;
        ////console.log("Entrada: ", newData);
        newData = newData.slice(0, index + 1);
        ////console.log("MeioTermo", newData);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        ////console.log("Saida: ", newData);
        setDataAuxEssayPrevalence(newData);
      } else {
        let newData = dataAuxEssayPrevalence;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setDataAuxEssayPrevalence(newData);
      }
    } else {
      let newData = dataAuxEssayPrevalence;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setDataAuxEssayPrevalence(newData);
    }
  };

  const handleEssayCount = (label, index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setDataCountEssay({ ...dataCountEssay, [label]: event });
      if (dataAuxEssayCount[index][event] != null) {
        let newData = dataAuxEssayCount;
        ////console.log("Entrada: ", newData);
        newData = newData.slice(0, index + 1);
        ////console.log("MeioTermo", newData);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        ////console.log("Saida: ", newData);
        setDataAuxEssayCount(newData);
      } else {
        let newData = dataAuxEssayCount;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setDataAuxEssayCount(newData);
      }
    } else {
      let newData = dataAuxEssayCount;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setDataAuxEssayCount(newData);
    }
  };

  //const [jsontree,setJsonTree] = useState(jsonFoods);

  const clearData = () => {
    setDataAux([jsonFoods]);
    //setJsonTree(jsonFoods);
    setData(null);
  };

  return (
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Accordion defaultExpanded={true} expanded={formExpanded === 1} className={formExpanded === 1 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Grid container alignItems="center">
            {formData.form1 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"1. Study, Agent & Essay"}</Typography>
        </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form1Ref} className={classes.formWide} onSubmit={handleSubmitForm1}>
        {isReadyToEdit ? 
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Study Info</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <Grid container direction="row">
          <Grid item container direction="column">
          <CustomSelect
            label={"Select Study ID" + required_text}
            //defaultInputValue={"StudyID" in bacteriaData ? "Abadias_IJFM_2006" : "Abadias_IJFM_2006"} //bacteriaData["StudyID"]
            defaultValue={"StudyID" in bacteriaData ? bacteriaData["StudyID"] : ""} //
            fullW
            name={"StudyID"}
            items={jsonstudies}
            labelError={blank_text_error}
            clearable={true}
          />
          </Grid>
          <Grid item>
          <Button
            onClick={() => callGetStudyInfoAPI()}
            variant="contained"
            size="large"
            //disabled={rows.length == 0 ? true : false}
            className={classes.customBTNNextRegister}
          >
            Study Info
          </Button>
          </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Agent Info</Typography>
                <Divider />
                <Paper className={fixedHeightPaper}>
                    <MaterialNativeSelect
                      name="Bacterium"
                      label={"Select Agent" + required_text}
                      labelError={blank_text_error}
                      onChange={(event) => handleAgent(event.target.value)}
                      defaultValue={"Bacterium" in bacteriaData ? bacteriaData["Bacterium"] : ""}
                      >
                      <option value={""}>Select</option >
                      {Object.keys(jsonAgent).map(key => 
                        key != "label" && key != "selected" ? (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ) : null
                      )}
                    </MaterialNativeSelect>

                { 
                Object.entries(jsonAgentData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                  defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                labelError={blank_text_error}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                <option key={dataValue} value={dataValue}>
                  {dataValue}
                </option >
                )}
                </MaterialNativeSelect>
                })}

                {selectedAgent ? 
                  <MaterialNativeSelect
                  name="essay"
                  label={"Select Essay"  + required_text}
                  labelError={blank_text_error}
                  onChange={(e) => handleEssayType(e.target.value)}
                  defaultValue={"Prevalence" in bacteriaData && "Count" in bacteriaData ? "2" 
                                : "Count" in bacteriaData ? "1"
                                : "Prevalence" in bacteriaData ? "0" : ""}
                >
                  <option value={""}>Select</option>
                  <option value={"0"}>Prevalence</option>
                  <option value={"1"}>Count</option>
                  <option value={"2"}>Both</option>
                </MaterialNativeSelect>
                : null}

                </Paper>
              </Grid>
        { essayType == 2 || essayType == 0 ?
        <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Prevalence</Typography>
              <Divider />
              <Paper className={fixedHeightPaper}>
              { 
                    (dataAuxEssayPrevalence.map((json,index) => {
                      return (
                        <MaterialNativeSelect
                          key={json.label}
                          label={json.label  + required_text}
                          labelError={blank_text_error}
                          onChange={(event) => handleEssayPrevalence(json.label,index,event.target.value)}
                          defaultValue={ "Prevalence" in bacteriaData ? [json.label] in bacteriaData.Prevalence ? bacteriaData.Prevalence[json.label] : "" : "" }
                          name={json.label}
                        >
                          <option value={""}>Select</option >
                          {Object.keys(json).map(key => 
                            key != "label" && key != "selected" ? (
                              <option key={key} value={key}>
                                {key}
                              </option >
                            ) : null
                          )}
                        </MaterialNativeSelect>
                    )}))
              }
              </Paper>
            </Grid>
        : null}

        { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Count</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
              (dataAuxEssayCount.map((json,index) => {
                return (
                  <MaterialNativeSelect
                    key={json.label}
                    label={json.label + required_text}
                    labelError={blank_text_error}
                    onChange={(event) => handleEssayCount(json.label,index,event.target.value)}
                    defaultValue={ "Count" in bacteriaData ? [json.label] in bacteriaData.Count ? bacteriaData.Count[json.label] : "" : "" }
                    name={json.label}
                  >
                    <option value={""}>Select</option >
                    {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                        <option key={key} value={key}>
                          {key}
                        </option >
                      ) : null
                    )}
                  </MaterialNativeSelect>
              )}))
        }
        </Paper>
        </Grid>
        : null}

        <Grid item xs={12} md={12} container justify="flex-end">
        <Button
            onClick={() => form1Ref.current.submitForm()}
            variant="contained"
            size="large"
            className={classes.customBTNNextRegister}
          >
            Next
        </Button>
        </Grid>

        </Grid>
        : null}
        </Form>
        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 2} className={formExpanded === 2 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form2 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"2. Food"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form2Ref} className={classes.formWide} onSubmit={handleSubmitForm2} >
        {isReadyToEdit ? 
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Info
          </Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>

          <CountrySelect 
              name={"FoodOrigin"} 
              label={"Food Origin" + required_text}
              labelError={blank_text_error}
              defaultValue={"FoodOrigin" in bacteriaData ? bacteriaData["FoodOrigin"] : ""} />
              
            { 
                (dataAux.map((json,index) => {
                  return (
                  <MaterialNativeSelect
                    key={json.label}
                    label={json.label + required_text}
                    labelError={blank_text_error}
                    onChange={(event) => handleChange(json.label,index,event.target.value)}
                    defaultValue={ [json.label] in bacteriaData ? bacteriaData[json.label] : "" }
                    name={json.label}
                  >
                    <option value={""}>Select</option >
                    {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                        <option key={key} value={key}>
                          {key}
                        </option >
                      ) : null
                    )}
                  </MaterialNativeSelect>
                )}))
            }
            {/*<Button onClick={clearData}>Clear Data</Button>*/}
          </Paper>
        </Grid>
          
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Characteristics
          </Typography>
          <Divider />
          {/*////console.log("RODOU")*/}
          <Paper className={fixedHeightPaper}>
          { 
                Object.entries(jsonFoodCharacs).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                  defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={""}
                labelError={blank_text_error}
                defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm2()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form2Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                Next
              </Button>
        </Grid>
        </Grid>
        : null }
        </Form>
        </AccordionDetails>
        </Accordion>      
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 3} className={formExpanded === 3 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form3 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"3. Results"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form3Ref} className={classes.formWide} onSubmit={handleSubmitForm3}>
        {isReadyToEdit ? 
        <Grid container spacing={3}>
        
        <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>General Results</Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
            { 
                Object.entries(jsonResultsData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                  defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={""}
                labelError={blank_text_error}
                name={key}
                defaultValue={ key in bacteriaData ? bacteriaData[key] : "" }
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })}
            </Paper>
          </Grid>
        
        { essayType == 2 || essayType == 0 ?
        <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Prevalence Results</Typography>
              <Divider />
              <Paper className={fixedHeightPaper}>
              { 
                  Object.entries(jsonPrevalenceData).map(([key, value]) => {
                    return value.data == null 
                    ?
                  <MaterialInput
                    key={key}
                    name={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    type={value.type || "text"}
                    isrequired={value.required}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                    defaultValue={ "Prevalence" in bacteriaData ? [key] in bacteriaData.Prevalence ? bacteriaData.Prevalence[key] : "" : "" }
                  />
                  :
                  <MaterialNativeSelect
                  key={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={""}
                  labelError={blank_text_error}
                  defaultValue={ "Prevalence" in bacteriaData ? [key] in bacteriaData.Prevalence ? bacteriaData.Prevalence[key] : "" : "" }
                  name={key}
                  >
                  <option value={""}>Select</option >
                  {value.data.map((dataValue) =>
                    dataValue != "label" && dataValue != "selected" ? (
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                    ) : null
                  )}
                  </MaterialNativeSelect>
              })}
              </Paper>
            </Grid>
        : null}

        { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Count Results</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
                  Object.entries(jsonCountData).map(([key, value]) => {
                    return value.data == null 
                    ?
                  <MaterialInput
                    key={key}
                    name={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    type={value.type || "text"}
                    isrequired={value.required}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                    defaultValue={ "Count" in bacteriaData ? [key] in bacteriaData.Count ? bacteriaData.Count[key] : "" : "" }
                  />
                  :
                  <MaterialNativeSelect
                  key={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={""}
                  labelError={blank_text_error}
                  name={key}
                  >
                  <option value={""}>Select</option >
                  {value.data.map((dataValue) =>
                    dataValue != "label" && dataValue != "selected" ? (
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                    ) : null
                  )}
                  </MaterialNativeSelect>
              })}
        </Paper>
        </Grid>
        : null}

        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm3()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form3Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                UPDATE
              </Button>
        </Grid>
        </Grid>
        : null}
        </Form>

        </AccordionDetails>
        </Accordion>        
        </Grid>
      </Grid>
    </Grid>

    <Dialog
      open={dialogStudyOpen}
      onClose={() => setDialogStudyOpen(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
      <DialogTitle id="scroll-dialog-title">Study Info</DialogTitle>
      <DialogContent dividers={true}>

      <Form ref={formStudyRef} className={classes.formWide} onSubmit={handleSubmitFormStudy}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
        <Paper className={fixedHeightPaper} elevation={0}>
          {Object.entries(jsonStudyStruct).map(([key, value]) => {
              return value.data == null 
              ?
          <MaterialInput
              key={key}
              name={key}
              label={value.label + (value.required ? required_text : blank_text)}
              type={value.type || "text"}
              disabled={key == "StudyID" ? true : false}
              isrequired={value.required}
              labelError={blank_text_error}
              placeholder={"enter text..."}
              defaultValue={ key in dialogStudyData ? dialogStudyData[key] : ""}
          />
          :
          <MaterialNativeSelect
          key={key}
          label={value.label + (value.required ? required_text : blank_text)}
          defaultValue={ key in dialogStudyData ? dialogStudyData[key] : "" }
          labelError={blank_text_error}
          name={key}
          >
          <option value={""}>Select</option >
          {value.data.map((dataValue) =>
          <option key={dataValue} value={dataValue}>
              {dataValue}
          </option >
          )}
          </MaterialNativeSelect>
          })}
          </Paper>
        </Grid>
        </Grid>
      </Form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row">
          <Button onClick={() => setDialogStudyOpen(false)} size="large" className={classes.customBTNBack}>
            Close
          </Button>
          <Button onClick={() => formStudyRef.current.submitForm()} size="large" className={classes.customBTNNextRegister}>
            Update Study
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>

      <Snackbar open={openSnackAlert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})}>
        <Alert onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} severity={openSnackAlert.severity}>
          <AlertTitle>{openSnackAlert.title}</AlertTitle>
          {openSnackAlert.msg}
        </Alert>
      </Snackbar>

    </Grid>
  );
}