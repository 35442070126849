import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../Styles/Colors";

const useStyles = makeStyles((theme) => ({
    customBTNBack: {
      backgroundColor: Colors.grayDark,
      color: Colors.white,
      marginRight: theme.spacing(1),
      minWidth: "100px",
      "&:hover": {
        backgroundColor: Colors.grayHigh,
      },
    },
    customBTN: {
      backgroundColor: Colors.secondaryDark,
      color: Colors.white,
      marginRight: theme.spacing(1),
      minWidth: "200px",
      "&:hover": {
        backgroundColor: Colors.secondaryUltraDark,
      },
    },
}));

export default function SubmitButton({onClick, isLoading=false, text=""}) {
    const classes = useStyles();

    return (
        <Button
        onClick={onClick}
        variant="contained"
        size="large"
        type="submit"
        disabled={isLoading ? true : false}
        className={classes.customBTN}
      >
        {isLoading ? <CircularProgress size={20}/> : text }
      </Button>
    )
}