export default {
    name: "default",
    colors: {
      primary: "#A546B6",
      secondary: "#57b646",
      background: "#EEEEEE",
      text: "#000000",
    },
    font: {
      size: 14,
      family: `"Open Sans", Helvetica, sans-serif`,
    },
    roundness: 8,
  };