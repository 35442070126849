import React, {useRef, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Colors from "../../../Styles/Colors";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';

//import {Link} from 'react-router';
import SnackAlert from "../../../Components/SnackAlert/SnackAlert";

import { Link as Linko } from "react-router-dom"; 


import { observer } from "mobx-react";
import useStores from "../../../Stores/useStore";

import { Form } from "@unform/web";
import api from "../../../Services/api";
import MaterialInput from "../../../Components/Inputs/MaterialInput";

import { useHistory } from "react-router-dom";


import logo_cimo from "../../../Assets/sidebar_logos/cimo_logo.svg";
import logo_ipb from "../../../Assets/sidebar_logos/ipb_logo.svg";
import logo_anses from "../../../Assets/sidebar_logos/anseslogo.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login() {
    const { history } = useHistory();
    const classes = useStyles();
    const formRef = useRef(null);
    const { appStore } = useStores();

    const [openSnackAlert,setOpenSnackAlert] = useState({
      open: false,
      severity: "info",
      title: "",
      msg: "",
    });

    function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://fsqa.esa.ipb.pt/">
              PathogensInFood
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

    async function handleSubmitLogin(data, { reset }, event) {
        if (data != null) {
          callLoginAPI(data);
        } else {
            // Show Snack Error
            //setOpenSnackAlert(true);
        }
    }

    const callLoginAPI = (data) => {
        api.post('/auth/login', data, {
            headers: {
              //'authorization': authToken
            }
          }).then(response => {
             //console.log("recebeu:", response.data);
            
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);

            appStore.setUser(response.data.user);
            appStore.setToken(response.data.token);

            //return window.location.reload();
          }).catch(err => {
            try {
              setOpenSnackAlert({
                open: true,
                severity: "error",
                title: "Error",
                msg: err.response.data.error
              });
              console.log(err); 
            } catch (e) {
              setOpenSnackAlert({
                open: true,
                severity: "error",
                title: "Error",
                msg: "ERROR - No Server Response Found!"
              });
            }
          });
      }
  
    return (
      <Grid container direction="column" justify="center" alignItems="center">
      <Grid container  item xs={9}>
        <Paper className={classes.paper} style={{width: '100%', padding: '15px', backgroundColor: 'rgba(71, 69, 69, 0.02)'}}>
            <Typography variant="h4" color="primary">Pathogens In Food Web System</Typography>  
        </Paper>
      </Grid>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Form
            ref={formRef}
            className={classes.form}
            onSubmit={handleSubmitLogin}
          >
            <MaterialInput
              variant="outlined"
              margin="normal"
              style={{backgroundColor: '#fafafa'}}
              required
              fullWidth
              id="Email"
              label="Email Address"
              name="Email"
              autoComplete="email"
              autoFocus
            />
            <MaterialInput
              variant="outlined"
              margin="normal"
              required
              style={{backgroundColor: '#fafafa'}}
              fullWidth
              name="Password"
              label="Password"
              type="password"
              id="Password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                
              </Grid>
              <Grid item>
                <Link href="/register" variant="body1">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

      <SnackAlert open={openSnackAlert.open} severity={openSnackAlert.severity} title={openSnackAlert.title} msg={openSnackAlert.msg} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} />

      </Grid>
    )
}

export default observer(Login);