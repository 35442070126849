import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TableCell from '@material-ui/core/TableCell';
import scrollbarSize from 'dom-helpers/scrollbarSize';
//import MaterialMultNativeSelect from "../../../Components/Selects/MaterialMultNativeSelect";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import api from "../../../Services/api";

import TableVirtualized from "../../../Components/Tables/TableVirtualized";


import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import MaterialInput from "../../../Components/Inputs/MaterialInput";

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";

import * as Yup from "yup";

import { Column, Table, AutoSizer } from "react-virtualized";

import useStores from "../../../Stores/useStore";

import ReactDOM from 'react-dom';
import { CellMeasurer, CellMeasurerCache, Grid as VirtualizedGrid} from 'react-virtualized';

import CircularProgress from '@material-ui/core/CircularProgress';

import SubmitButton from "../../../Components/Button/SubmitButton"; 


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  cellHeader: {
    color: Colors.black,
    backgroundColor: Colors.primaryUltraLight,
  },
  cellRow: {
    color: Colors.grayHigh,
  },
  dividerHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disabledHeader: {
    backgroundColor: Colors.grayMedium,
  },
  expandedHeader: {},
  doneStepIcon: {
    color: Colors.primaryDarkHigh,
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: "bold",
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTNBack: {
    backgroundColor: Colors.grayDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "100px",
    "&:hover": {
      backgroundColor: Colors.grayHigh,
    },
  },
  customBTNNextRegister: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
  contResultsLabel: {
    color: Colors.grayDark
  },
}));

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

const cache = new CellMeasurerCache({
  defaultWidth: 100,
  minWidth: 75,
  fixedHeight: false,
  minHeight: 20,
});

export default function ParasiteSearchLabel() {
  const blank_text = Texts.leave_blank;
  const required_text = Texts.required;
  const blank_text_error = Texts.blank_error;
  const { appStore } = useStores();

  const [rows,setRows] = useState([]);
  const [jsonAgent,setJsonAgent] = useState({});

  const [isLoading,setIsLoading] = useState(false);

  const form1Ref = useRef(null);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    callParasiteAPI(0);
  },[]);


  const callParasiteAPI = (attempt) => {
    if (attempt >= 2) {
      setJsonAgent({});
      return ;
    }
    // Run API Call
    api.get('/parasite/struct/parasite_tree').then(response => {
      //console.log("recebeu:", response.data);
      if (!response.data) {
          //console.log("entrou aqui");
      }
      setJsonAgent(response.data);
      return;
    }).catch(err => {
      callParasiteAPI(attempt+1);
    });
  }

  async function handleSubmitForm1(data, { reset }, event) {

    if (data != null) {
      callSearchAPI(data);
    } else {
      // Show Snack Error
      //setOpenSnackAlert(true);
    }
  }

  const authToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5ODY2ODI5OSwiZXhwIjoxNTk4OTI3NDk5fQ.u-BC6fLLBtB_FKTePdlKXv5ij37yVV8cm_crWMucOdI";
  
    const callBibDownloadAPI = () => {
      let StudyIDs = [];
      if (rows.length != 0) {
        let index = rows[0].findIndex((element) => element = "StudyID");
        if (index != -1) {
          rows.slice(1,rows.length).map(row => {
            if (StudyIDs.findIndex((element) => element == row[index]) == -1) {
              StudyIDs.push(row[index]);
            }
          })
        }
      }
      
      if (StudyIDs.length > 0) {
        api.post('/study/list/bib', {StudyIDs: StudyIDs}, {
          headers: {
            'authorization': authToken
          }
        }).then(response => {

          if (response.data != null) {
            const type = response.headers['content-type'];
            //console.log("Type: ",type);
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'file.bib'
            link.click()
          }

        }).catch(function (error) {
          //console.log("ERROR");
        });
      }
      
      return StudyIDs;
    }

    const callSearchAPI = (data) => {
    setIsLoading(true);
    api
      .get("/parasite/list/search_label", {params: {...data}, headers: {authorization: authToken}})
      .then((response) => {
        //console.log("recebeu:", response.data);

        if(response.data == [] || response.data == "" || response.data == " ") {
          setRows([]);
          return setIsLoading(false);
        }
        
        // Return JSON as Download File (blob)
        if (data.type == "json") {
          const type = response.headers['content-type']
          const str = JSON.stringify(response.data);
          const bytes = new TextEncoder().encode(str);
          const blob = new Blob([bytes], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'file.'+data.type
          link.click()
        // Return CSV as Download File (blob)
        } else if (data.type == "csv") {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'file.'+data.type
          link.click()
        // Return Table as Visualization in HTML (React)
        } else {
          setRows(response.data);
        }
        return setIsLoading(false);
      })
      .catch(function (error) {
        let exists = false;
        //console.log(error);
        //console.log(error.response);
        try {
          if (
            "response" in error &&
            "data" in error.response &&
            "error" in error.response.data
          ) {
            exists = true;
          }
        } catch {
          exists = false;
        }
        // Snack Error
        return setIsLoading(false);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Form
        ref={form1Ref}
        className={classes.formWide}
        onSubmit={handleSubmitForm1}
      >
        <Grid container spacing={3} style={{marginBottom: '15px'}}>
          <Grid item xs={12} md={6}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Pathogen</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <MaterialNativeSelect
            name="agent"
            label={"Select Agent"}
            labelError={blank_text_error}
          >
            <option value={""}>all</option>
            {Object.keys(jsonAgent).map(key => 
              key != "label" && key != "selected" ? (
                <option key={key} value={key}>
                  {key}
                </option >
              ) : null
            )}
          </MaterialNativeSelect>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Type</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <MaterialNativeSelect
            name="essay"
            label={"Select Essay"}
            labelError={blank_text_error}
          >
            <option value={""}>all</option>
            <option value={"Prevalence"}>Prevalence</option>
            <option value={"Both"}>Prevalence+Enumeration</option>
          </MaterialNativeSelect>
          </Paper>
        </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Search By Label
          </Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
            <MaterialInput
              name={"label"}
              label={"Label (Search Terms)"}
              type={"text"}
              isrequired={false}
              labelError={blank_text_error}
              placeholder={"enter text..."}
            />
            <Grid container>
            <Grid container item  xs={12} md={6} direction="column" style={{paddingRight: 5}}>
            <MaterialNativeSelect
              name="type"
              label={"Show Search Results as:"}
              labelError={blank_text_error}
            >
              <option value={"table"}>Table</option>
              {appStore.isAllowed() ? 
                <React.Fragment>
                  <option value={"csv"}>CSV (Download)</option>
                  <option value={"json"}>JSON (Download)</option>
                </React.Fragment>
              : null}
            </MaterialNativeSelect>
            </Grid>
            <Grid container item xs={12} md={6} direction="column">
            <MaterialNativeSelect
              name="defaultValue"
              label={"Fill Empty Cells with:"}
              labelError={blank_text_error}
            >
              <option value={""}>Blank Field</option>
              <option value={"null"}>Null</option>
              <option value={"undefined"}>Undefined</option>
            </MaterialNativeSelect>
            </Grid>
            </Grid>

            <Grid item xs={12} md={12} container justify="space-between">
              <SubmitButton
                onClick={() => form1Ref.current.submitForm()}
                isLoading={isLoading}
                text={"Search"}
              />
              {appStore.isAllowed() ? 
                <Button
                  onClick={() => callBibDownloadAPI()}
                  variant="contained"
                  size="large"
                  disabled={rows.length == 0 ? true : false}
                  className={classes.customBTNBack}
                >
                  Download Bib
                </Button>
              : null}
            </Grid>
          </Paper>
        </Grid>
      </Form>
      </Grid>

      <Grid item xs={12} md={12}>
        <TableVirtualized rows={rows} />
      </Grid>
    </Grid>
  );
}
