import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
//import useStores from "../stores/useStores";
import { observer } from "mobx-react";
//import PublicMainLayout from "../Layout/Public/PublicMain";
import Register from "../Pages/Authentication/Register";
import Login from "../Pages/Authentication/Login";

function NonUserRoutes() {

  return (
    <BrowserRouter>
      <Switch>
            <Route path="/register" exact component={Register} />
            <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

export default observer(NonUserRoutes);