import React, {useRef, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Colors from "../../../Styles/Colors";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Container from '@material-ui/core/Container';
import { observer } from "mobx-react";

import { Form } from "@unform/web";
import api from "../../../Services/api";
import MaterialInput from "../../../Components/Inputs/MaterialInput";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import logo_cimo from "../../../Assets/sidebar_logos/cimo_logo.svg";
import logo_ipb from "../../../Assets/sidebar_logos/ipb_logo.svg";
import logo_anses from "../../../Assets/sidebar_logos/anseslogo.jpg";

import useStores from "../../../Stores/useStore";
import SnackAlert from "../../../Components/SnackAlert/SnackAlert"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Register() {
    const classes = useStyles();
    const formRef = useRef(null);
    const { appStore } = useStores();

    const [openSnackAlert,setOpenSnackAlert] = useState({
      open: false,
      severity: "info",
      title: "",
      msg: "",
    });

    function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://pathogensinfood.esa.ipb.pt/">
              PathogensInFood
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }

    const validateForm = (data) => {
      let msg = null;
      if (data.Password !== data.PasswordConfirm) {
        msg = "Passwords are not the same!";
      } else if (data.Password.length <= 5) {
        msg = "Password must be 6 or more characters!";
      } else if (!data.Email.includes('@')) {
        msg = "Invalid email!";
      }

      if (msg !== null) {
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: msg
        });
        return false;
      } else {
        return true;
      }
    }

    async function handleSubmitRegister(data, { reset }, event) {
        if (data != null) {
          
          if (validateForm(data)) {
            delete data.PasswordConfirm;
            callRegisterAPI(data);
          }
        } else {
            // Show Snack Error
            //setOpenSnackAlert(true);
        }
    }

    const callRegisterAPI = (data) => {
        api.post('/auth/register', data, {
            headers: {
              //'authorization': authToken
            }
          }).then(response => {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', response.data.token);

            appStore.setUser(response.data.user);
            appStore.setToken(response.data.token);
            
          }).catch(err => {
            try {
              setOpenSnackAlert({
                open: true,
                severity: "error",
                title: "Error",
                msg: err.response.data.error
              });
               
            } catch (e) {
              setOpenSnackAlert({
                open: true,
                severity: "error",
                title: "Error",
                msg: "ERROR - No Server Response Found!"
              });
            }
          });
      }
  
    return (
      <Grid container direction="column" justify="center" alignItems="center">
      <Grid container  item xs={9}>
        <Paper className={classes.paper} style={{width: '100%', padding: '15px', backgroundColor: 'rgba(71, 69, 69, 0.02)'}}>
            <Typography variant="h4" color="primary">Pathogens In Food Web System</Typography>  
        </Paper>
      </Grid>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Form
            ref={formRef}
            className={classes.form}
            onSubmit={handleSubmitRegister}
          >
            <MaterialInput
              variant="outlined"
              margin="normal"
              style={{backgroundColor: '#fafafa'}}
              required
              fullWidth
              id="GivenNames"
              label="Given Name"
              name="GivenNames"
              autoFocus
            />
            <MaterialInput
              variant="outlined"
              margin="normal"
              style={{backgroundColor: '#fafafa'}}
              required
              fullWidth
              id="Surname"
              label="Surname"
              name="Surname"
            />
            <MaterialInput
              variant="outlined"
              margin="normal"
              style={{backgroundColor: '#fafafa'}}
              required
              fullWidth
              id="Email"
              label="Email Address"
              name="Email"
              autoComplete="email"
            />
            <MaterialInput
              variant="outlined"
              margin="normal"
              style={{backgroundColor: '#fafafa'}}
              required
              fullWidth
              id="Company"
              label="Company"
              name="Company"
              autoComplete="email"
            />
            <MaterialNativeSelect
              name="Occupation"
              style={{backgroundColor: '#fafafa'}}
              label={"Occupation"}
              required
              fullWidth
              >
              <option value={""}>Select</option >
              <option value={"Student"}>Student</option >
              <option value={"Researcher"}>Researcher</option >
              <option value={"Professor"}>Professor</option >
              <option value={"Other"}>Other</option >
            </MaterialNativeSelect>
            <MaterialInput
              variant="outlined"
              margin="normal"
              required
              style={{backgroundColor: '#fafafa'}}
              fullWidth
              name="Password"
              label="Password"
              type="password"
              id="Password"
              autoComplete="current-password"
            />
            <MaterialInput
              variant="outlined"
              margin="normal"
              required
              style={{backgroundColor: '#fafafa'}}
              fullWidth
              name="PasswordConfirm"
              label="Confirm Password"
              type="password"
              id="PasswordConfirm"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item xs>
                {/*<Link href="#" variant="body2">
                  Forgot password?
                  </Link>*/}
              </Grid>
              <Grid item>
                <Link href="/login" variant="body1">
                  {"Already have an account? Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

      <SnackAlert open={openSnackAlert.open} severity={openSnackAlert.severity} title={openSnackAlert.title} msg={openSnackAlert.msg} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} />

      </Grid>
    )
}

export default observer(Register);