import React, { useMemo, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  ListSubheader,
  Collapse,
  withStyles
} from "@material-ui/core";
import _ from "lodash";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import TagIcon from "@material-ui/icons/LocalOffer";
import CategoryIcon from "@material-ui/icons/Label";
import WineIcon from "@material-ui/icons/LocalBar";
import LayersIcon from "@material-ui/icons/Layers";
import DoneIcon from "@material-ui/icons/Done";
import GroupIcon from "@material-ui/icons/Group";
import LockIcon from "@material-ui/icons/Lock";
import StoreIcon from "@material-ui/icons/Storefront";
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import { useHistory } from "react-router-dom";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CardMedia from '@material-ui/core/CardMedia';
import SvgIcon from '@material-ui/core/SvgIcon';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';

import InfoIcon from '@material-ui/icons/Info';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

import Colors from '../Styles/Colors';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DescriptionIcon from '@material-ui/icons/Description';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import MenuIcon from '@material-ui/icons/Menu';

import drawerBG from "../Assets/cimo_bg_menu2.jpg";
import logo_menu from "../Assets/logo_menu.png";

import logo_cimo from "../Assets/sidebar_logos/cimo_logo.svg";
import logo_ipb from "../Assets/sidebar_logos/ipb_logo.svg";
import logo_anses from "../Assets/sidebar_logos/anseslogo.jpg";

import ViewListIcon from '@material-ui/icons/ViewList';

import {Divider} from '@material-ui/core';

import useStores from "../Stores/useStore";

import { ReactComponent as Bacteria } from "../Assets/Icons/bacteria.svg";
import { ReactComponent as Virus } from "../Assets/Icons/virus.svg";
import { ReactComponent as Parasite } from "../Assets/Icons/parasite.svg";

import GC from '../Configs/global';

function BacteriaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
    backgroundImage: 'url(' + drawerBG + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(1),
    //'&::-webkit-scrollbar': {
    //    display: "none"
    //}
  },
  itemIcon: {
    color: '#ffffff',
  },
  /*logoImg: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '100px',
    backgroundImage: 'url(' + logo_menu + ')',
  },
  dividerOpacity: {
    
  },*/
  subList: {
    paddingLeft: theme.spacing(2),
  },
  itemHeader: {
    color: Colors.secondaryLight,
    fontSize: '16px',
  },
  collapseStyled: {
  },
  cardImg: {
    borderRadius: 5,
    width: '80%',
    height: 'auto',
    backgroundColor: 'rgba(255,255,255,1)',
    opacity: 0.4,
    margin: "5px 1px 5px 1px",
    textAlign: 'center'
  }
}));

const StyledListItem = withStyles({
  root: {
    color: '#ffffff',
    borderRadius: '5px',
    marginTop: '5px',
    border: `double ${Colors.transparentWhite}`,
    borderWidth: '1px 0px 0px 0px',
    "&$selected": {
      backgroundColor: Colors.secondaryDark,
      "&:hover": {
        backgroundColor: Colors.secondary,
      }
    },
    "&:hover": {
      backgroundColor: Colors.grayDark
    }
  },
  selected: {}
})(ListItem);

const StyledSubListItem = withStyles({
  root: {
    color: '#ffffff',
    borderRadius: '5px',
    "&$selected": {
      backgroundColor: Colors.secondaryDark,
      "&:hover": {
        backgroundColor: Colors.secondary,
      }
    },
    "&:hover": {
      backgroundColor: Colors.grayDark
    }
  },
  selected: {}
})(ListItem);

export default function SideBar({ isOpen, setTitle }) {
  const classes = useStyles();
  const { location, ...history } = useHistory();
  const { appStore } = useStores();

  const [open, setOpen] = useState({
    db_bacteria: false,
    db_virus: false,
    db_parasite: false,
    db_study: false,
    db_main: false,
    tree_bacteria: false,
    tree_study: false,
    tree_food: false,
  });

  const role_menus = {
    "admin": [
      {
        name: "Main Menu",
        listItems: [
          {
            name: "Dashboard",
            icon: <MenuIcon />,
            state: "db_main",
            subListItems: [
              {
                name: "About",
                icon: <InfoIcon />,
                path: GC.routes.menu.default,
              },
              {
                name: "Meta Analysis",
                icon: <BarChartIcon />,
                path: GC.routes.menu.metaAnalysis,
              },
              {
                name: "Distributions",
                icon: <PieChartIcon />,
                path: GC.routes.menu.distributions,
              },
            ]
          }
        ]
      },
      {
        name: "Database Management",
        listItems: [
        {
          name: "Study",
          icon: <DescriptionIcon />,
          state: "db_study",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.study.search,
            },
            {
              name: "Register New",
              icon: <AddCircleOutlineOutlinedIcon />,
              path: GC.routes.study.new,
            },
            {
              name: "Manage Data",
              icon: <ViewListIcon />,
              path: GC.routes.study.manage,
            }
          ]
        },
        {
          name: "Bacteria",
          icon: <SvgIcon component={Bacteria} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_bacteria",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.bacteria.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.bacteria.searchLabel,
            },
            {
              name: "Register New",
              icon: <AddCircleOutlineOutlinedIcon />,
              path: GC.routes.bacteria.new,
            },
            {
              name: "Curate Data",
              icon: <ViewListIcon />,
              path: GC.routes.bacteria.manage,
            }
          ]
        },
        {
          name: "Virus",
          icon: <SvgIcon component={Virus} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_virus",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.virus.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.virus.searchLabel,
            },
            {
              name: "Register New",
              icon: <AddCircleOutlineOutlinedIcon />,
              path: GC.routes.virus.new,
            },
            {
              name: "Curate Data",
              icon: <ViewListIcon />,
              path: GC.routes.virus.manage,
            }
          ]
        },
        {
          name: "Parasite",
          icon: <SvgIcon component={Parasite} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_parasite",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.parasite.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.parasite.searchLabel,
            },
            {
              name: "Register New",
              icon: <AddCircleOutlineOutlinedIcon />,
              path: GC.routes.parasite.new,
            },
            {
              name: "Curate Data",
              icon: <ViewListIcon />,
              path: GC.routes.parasite.manage,
            }
          ]
        },
        ]
      },
      /*{
        name: "Tree Management",
        listItems: [
        {
          name: "Study",
          icon: <DescriptionIcon/>,
          state: "tree_study",
          subListItems: [
            {
              name: "Study Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/study/",
            },
          ]
        },
        {
          name: "Food Class",
          icon:  <FastfoodIcon />,
          state: "tree_food",
          subListItems: [
            {
              name: "Food Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/food/",
            },
          ]
        },
        {
          name: "Bacteria",
          icon: <SvgIcon component={Bacteria} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "tree_bacteria",
          subListItems: [
            {
              name: "Agents Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/bacteria/agents",
            },
            {
              name: "Count Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/bacteria/count",
            },
            {
              name: "Prevalence Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/bacteria/prevalence",
            },
            {
              name: "General Tree",
              icon: <AccountTreeIcon />,
              path: "/tree/bacteria/general",
            },
          ]
        },        
        ]
      },*/
      {
        name: "System Management",
        listItems: [
          {
            name: "Users",
            icon: <AccountCircle />,
            state: "system_manage",
            subListItems: [
              {
                name: "Manage Users",
                icon: <ViewListIcon />,
                path: GC.routes.users.manage,
              },
            ]
          },
        ]   
      },
    ],
    "advanced": [
      {
        name: "Main Menu",
        listItems: [
          {
            name: "Dashboard",
            icon: <MenuIcon />,
            state: "db_main",
            subListItems: [
              {
                name: "About",
                icon: <InfoIcon />,
                path: GC.routes.menu.default,
              },
              {
                name: "Meta Analysis",
                icon: <BarChartIcon />,
                path: GC.routes.menu.metaAnalysis,
              },
              {
                name: "Distributions",
                icon: <PieChartIcon />,
                path: GC.routes.menu.distributions,
              },
            ]
          }
        ]
      },
      {
        name: "Database",
        listItems: [
        {
          name: "Bacteria",
          icon: <SvgIcon component={Bacteria} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_bacteria",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.bacteria.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.bacteria.searchLabel,
            },
          ]
        },
        {
          name: "Virus",
          icon: <SvgIcon component={Virus} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_virus",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.virus.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.virus.searchLabel,
            },
          ]
        },
        {
          name: "Parasite",
          icon: <SvgIcon component={Parasite} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_parasite",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.parasite.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.parasite.searchLabel,
            },
          ]
        },
        ]
      },
    ],
    "basic": [
      {
        name: "Main Menu",
        listItems: [
          {
            name: "Dashboard",
            icon: <MenuIcon />,
            state: "db_main",
            subListItems: [
              {
                name: "About",
                icon: <InfoIcon />,
                path: GC.routes.menu.default,
              },
            ]
          }
        ]
      },
      {
        name: "Database",
        listItems: [
        {
          name: "Bacteria",
          icon: <SvgIcon component={Bacteria} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_bacteria",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.bacteria.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.bacteria.searchLabel,
            },
          ]
        },
        {
          name: "Virus",
          icon: <SvgIcon component={Virus} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_virus",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.virus.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.virus.searchLabel,
            },
          ]
        },
        {
          name: "Parasite",
          icon: <SvgIcon component={Parasite} fontSize="large" viewBox="0 0 600 476.6" className={classes.itemIcon} />,
          state: "db_parasite",
          subListItems: [
            {
              name: "Search",
              icon: <SearchOutlinedIcon />,
              path: GC.routes.parasite.search,
            },
            {
              name: "Search By Label",
              icon: <PageviewOutlinedIcon />,
              path: GC.routes.parasite.searchLabel,
            },
          ]
        },
        ]
      },
    ]
  }

  const routes = useMemo(() => 
    appStore.user.role == "admin" 
    ? role_menus.admin
    : appStore.user.role == "advanced"
    ? role_menus.advanced
    : role_menus.basic
  );

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{ paper: classes.drawerPaper }}
      elevation={0}
    >
      <CardMedia
          image={logo_menu}
          component="img"
          title="Logo"
        />
      <List>
      <Divider className={classes.dividerOpacity} />
      {routes.map(({ name, listItems }) => (
          <React.Fragment key={name}>
            <ListSubheader className={classes.itemHeader} component="div" disableSticky>{name}</ListSubheader>
            {listItems.map((item) => {
            return (<React.Fragment key={item.name}>
            <StyledListItem
              button
              onClick={(e) => setOpen({...open, [item.state]: !open[item.state]})}
              >
              <ListItemIcon className={classes.itemIcon}> {item.icon} </ListItemIcon>
              <ListItemText primary={item.name} />
              {open[item.state] ? <ExpandLess /> : <ExpandMore />}
            </StyledListItem>
            <Collapse in={open[item.state]} className={classes.subList} timeout="auto" unmountOnExit>
            <List component="div" className={classes.collapseStyled} disablePadding>
            {item.subListItems.map((subItem) => {
              return (<StyledSubListItem
              selected={location.pathname === subItem.path}
              key={subItem.name}
              button
              onClick={() => {history.push(subItem.path); setTitle(subItem.name+" ("+item.name+")")}}
              >
              <ListItemIcon className={classes.itemIcon}>{subItem.icon}</ListItemIcon>
              <ListItemText primary={subItem.name} />
            </StyledSubListItem>)
            })}
            </List>
            </Collapse>
            </React.Fragment>)
            })}
            <Divider className={classes.dividerOpacity} />
          </React.Fragment>
      ))}

      </List>
      
      {/*<CardMedia
          className={classes.cardImg}
          image={logo_cimo}
          component="img"
          title="Logo"
        />
        <CardMedia
          className={classes.cardImg}
          image={logo_ipb}
          component="img"
          title="Logo"
        />
        <CardMedia
          className={classes.cardImg}
          image={logo_anses}
          component="img"
          title="Logo"
      />*/}
    </Drawer>
  );
}