import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import cloneDeep from 'lodash/cloneDeep';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from "../../../Services/api";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Button from "@material-ui/core/Button";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import MaterialInput from "../../../Components/Inputs/MaterialInput";

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";
import TableVirtualizedSelected from "../../../Components/Tables/TableVirtualizedSelected";

import SubmitButton from "../../../Components/Button/SubmitButton"; 

import { AlertTitle } from '@material-ui/lab';

import GC from '../../../Configs/global';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  dividerHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disabledHeader: {
    backgroundColor: Colors.grayMedium,
  },
  expandedHeader: {},
  doneStepIcon: {
    color: Colors.primaryDarkHigh,
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: "bold",
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTNBack: {
    backgroundColor: Colors.grayDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.grayHigh,
    },
  },
  customBTNRemove: {
    backgroundColor: Colors.errorRed1,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.errorRed2,
    },
  },
  customBTNEdit: {
    backgroundColor: Colors.sucessGreen1,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.sucessGreen2,
    },
  },
  formWide: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
  contResultsLabel: {
    color: Colors.grayDark
  },
  confirmRemoveID: {
    color: Colors.black,
    fontWeight: 'bold',
    marginTop: 15,
  }
}));

export default function BacteriaManage() {
  const blank_text = Texts.leave_blank;
  const blank_text_error = Texts.blank_error;

  const [isLoading,setIsLoading] = useState(false);
  const [rows,setRows] = useState([]);
  const form1Ref = useRef(null);
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [runOnce,setRunOnce] = useState(false);
  const [filterOnce, setFilterOnce] = useState(false);
  const [columns, setColumns] = useState([]);

  const [rowSelected,setRowSelected] = useState(null);
  const [removeDialog,setRemoveDialog] = useState({
    open:false,
    id:null,
  });

  const [openSnackAlert,setOpenSnackAlert] = useState({
    open: false,
    severity: "info",
    title: "",
    msg: "",
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const authToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5ODY2ODI5OSwiZXhwIjoxNTk4OTI3NDk5fQ.u-BC6fLLBtB_FKTePdlKXv5ij37yVV8cm_crWMucOdI";

  useEffect( () => {
    if (!runOnce) {
      callSearchAPI({search: "", column: ""});
      setRunOnce(true);
    }
  },[]);

  async function handleSubmitForm1(data, { reset }, event) {
    setRowSelected(null);

    const resultData = {search: data.search, column: data.column};
    if (data != null) {
      callSearchAPI(resultData);
    } else {
      // Show Snack Error
      //setOpenSnackAlert(true);
    }
  }

  const callEditAPI = (id) => {
    if (id != null) {
      //console.log("Rodou");
      window.open(GC.routes.bacteria.manageEditNoParam+id, "_blank");
    }
  }

    const callRemoveAPI = () => {
      setRemoveDialog({...removeDialog, open: false});
      setIsLoading(true);
      api
      .delete("/bacteria/manage/remove", {params: {id: removeDialog.id}, headers: {authorization: authToken}})
      .then((response) => {
        setOpenSnackAlert({
          open: true,
          severity: "success",
          title: "Success",
          msg: Texts.snack_msg.remove_sucess
        });

        setRowSelected(null);
        setIsLoading(false);
        return form1Ref.current.submitForm();
      })
      .catch(function (error) {
        let exists = false;
        //console.log(error);
        //console.log(error.response);
        try {
          if (
            "response" in error &&
            "data" in error.response &&
            "error" in error.response.data
          ) {
            exists = true;
          }
        } catch {
          exists = false;
        }
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: Texts.snack_msg.remove_error
        });
        return setIsLoading(false);
        // Snack Error
      });
    }

    const callSearchAPI = (data) => {
    setIsLoading(true);
    api
      .get("/bacteria/manage/search_manage", {params: {...data}, headers: {authorization: authToken}})
      .then((response) => {
        //console.log(response.data);

        if(response.data == [] || response.data == "" || response.data == " ") {
          setRows([]);
          return setIsLoading(false);
        }
        
        setRows(response.data);
        if (!filterOnce) {
          setFilterOnce(true);
          let columns_formatted = cloneDeep(response.data[0]);
          setColumns(columns_formatted.sort());
        }
        return setIsLoading(false);

      })
      .catch(function (error) {
        let exists = false;
        //console.log(error);
        //console.log(error.response);
        try {
          if (
            "response" in error &&
            "data" in error.response &&
            "error" in error.response.data
          ) {
            exists = true;
          }
        } catch {
          exists = false;
        }

        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: Texts.snack_msg.reponse_failed
        });

        return setIsLoading(false);
        // Snack Error
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Form
        ref={form1Ref}
        className={classes.formWide}
        onSubmit={handleSubmitForm1}
      >
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Filter By Column
          </Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
            <Grid container>
            <Grid container item  xs={12} md={4} direction="column" style={{paddingRight: 5}}>
            <MaterialInput
              name={"search"}
              label={"Filter Terms"}
              type={"text"}
              isrequired={false}
              labelError={blank_text_error}
              placeholder={"enter text..."}
            />
            </Grid>
            <Grid container item xs={12} md={4} direction="column">
            <MaterialNativeSelect
              name="column"
              label={"Filter Column:"}
              labelError={blank_text_error}
            >
              <option value={""}>None</option>
              {
              columns.map(col => (col != '_id') ? (
                <option key={col} value={col}>{col}</option>
              ) : null)
              }
            </MaterialNativeSelect>
            </Grid>
            <Grid container item xs={12} md={4} direction="column" alignItems="center" justify="center">
            <SubmitButton
                onClick={() => form1Ref.current.submitForm()}
                isLoading={isLoading}
                text={"Search"}
              />
            </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Form>
      </Grid>

      <Grid container item xs={12} md={12} direction="row" alignItems="center" justify="flex-end">
      <Button
                disabled={rowSelected === null ? true : false}
                onClick={() => callEditAPI(rows[rowSelected][0])}
                className={classes.customBTNEdit}
            >
              Edit Selected
            </Button>
            <Button
                disabled={rowSelected === null ? true : false}
                className={classes.customBTNRemove}
                onClick={() => setRemoveDialog({open: true, id: rows[rowSelected][0]})}
            >
              Remove Selected
            </Button>
      </Grid>
      
      <Grid item xs={12} md={12}>
      <TableVirtualizedSelected rows={rows} setRowSelected={setRowSelected} rowSelected={rowSelected} tableHeight={600} />
      </Grid>

      <Dialog
        open={removeDialog.open}
        onClose={() => setRemoveDialog({...removeDialog, open: false})}
        classes={ {paper: classes.dialogPaper} }
      >
        <DialogTitle id="alert-dialog-title">Remove Confirmation</DialogTitle>
        <Divider />
        <DialogContent style={{marginTop: "15px"}}>
          Do you really want to delete the selected item?
          <DialogContentText id="alert-dialog-description" className={classes.confirmRemoveID}>
            ID: {removeDialog.id != null ? removeDialog.id : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-evenly">
          <Button onClick={() => setRemoveDialog({...removeDialog, open: false})} className={classes.customBTNBack}>
            NO
          </Button>
          <Button onClick={() => callRemoveAPI()} className={classes.customBTNRemove}>
            YES
          </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackAlert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})}>
        <Alert onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} severity={openSnackAlert.severity}>
          <AlertTitle>{openSnackAlert.title}</AlertTitle>
          {openSnackAlert.msg}
        </Alert>
      </Snackbar>

    </Grid>
  );
}