import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {useParams} from "react-router-dom";
//import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import api from '../../../Services/api';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import Button from "@material-ui/core/Button";

import { Form } from "@unform/web";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import MaterialInput from "../../../Components/Inputs/MaterialInput";
//import MaterialSelect from "../../../Components/Selects/MaterialSelect";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CustomSelect from "../../../Components/Selects/CustomSelect";
import CountrySelect from "../../../Components/CountrySelect";


import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";

import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  dividerHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disabledHeader: {
    backgroundColor: Colors.grayMedium,
  },
  expandedHeader: {
    
  },
  doneStepIcon: {
    color: Colors.primaryDarkHigh
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: 'bold',
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTNBack: {
    backgroundColor: Colors.grayDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "100px",
    "&:hover": {
      backgroundColor: Colors.grayHigh,
    },
  },
  customBTNNextRegister: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ParasiteEdit() {
  const blank_text = Texts.leave_blank;
  const required_text = Texts.required;
  const blank_text_error = Texts.blank_error;

  const { parasite_id } = useParams();

  const form1Ref = useRef(null);
  const form2Ref = useRef(null);
  const form3Ref = useRef(null);
  const formStudyRef = useRef(null);

  const formRef = useRef(null);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [formData,setFormData] = useState({
    form1: null,
    form2: null,
    form3: null
  });

  const [dialogData,setDialogData] = useState({
    open: false,
    type: "normal",
    loading: false,
    title: "",
    msg: "",
  });

  // APIs JSON Data
  const [jsonstudies, setJsonStudies] = useState([]); // Study IDs
  const [jsonStudyStruct,setJsonStudyStruct] = useState([]); // Study Struct

  const [jsonFoods, setJsonFoods] = useState({}); // Food Info [TREE]
  const [jsonFoodCharacs,setJsonFoodCharacs] = useState({}); // Food Chacaracteristics

  // ************ PARASITE SPECIFIC STATES ************************

  //const [jsonAgent,setJsonAgent] = useState({}); // Agent Info [TREE]
  const [jsonAgent,setJsonAgent] = useState([]);
  const [jsonAgentTree,setJsonAgentTree] = useState({});
  
  const [jsonAgentData,setJsonAgentData] = useState({}) // Agent Info

  const [jsonSubtype,setJsonSubtype] = useState(null);
  const [jsonSubtypeAux,setJsonSubtypeAux] = useState([]);
  const [jsonSubtypeTree,setJsonSubtypeTree] = useState({});

  const [jsonEssaySample,setJsonEssaySample] = useState([]);
  const [jsonEssaySampleTree,setJsonEssaySampleTree] = useState({});

  const [jsonEssayIdentification,setJsonEssayIdentification] = useState([]);
  const [jsonEssayIdentificationTree,setJsonEssayIdentificationTree] = useState({});

  const [jsonEssayInfectivity,setJsonEssayInfectivity] = useState([]);
  const [jsonEssayInfectivityTree,setJsonEssayInfectivityTree] = useState({});

  //const [jsonEssayPrevEnum2,setJsonEssayPrevEnum2] = useState([]);
  const [jsonEssayPrevalence2,setJsonEssayPrevalence2] = useState([]);
  const [jsonEssayEnum2,setJsonEssayEnum2] = useState([]);
  const [jsonEssayPrevEnumTree,setJsonEssayPrevEnumTree] = useState({});

  // STEP 3 -> Results Prevalence Block
  const [jsonPrevalenceOnlyResultsData,setJsonPrevalenceOnlyResultsData] = useState([]);
  const [jsonPrevalenceResultsData,setJsonPrevalenceResultsData] = useState([]);

  const [jsonParasiteUnit,setJsonParasiteUnit] = useState([]);
  const [jsonParasiteUnitTree,setJsonParasiteUnitTree] = useState({});
  // STEP 3 -> Results Enumeration Block
  const [jsonEnumResultsData,setJsonEnumResultsData] = useState([]);
  // STEP 3 -> Infectivity Block
  const [jsonInfectivityResultsData,setJsonInfectivityResultsData] = useState([]);

  const [jsonFullStruct,setJsonFullStruct] = useState({});

  const [isMeatMidProcessing, setIsMeatMidProcessing] = useState({
    meat: false,
    midproc: false,
  });

  // PAGE CONDITICIONALS
  const [isInfectivitySelected,setIsInfectivitySelected] = useState(false);
  const [isMolluscsSelected,setIsMolluscsSelected] = useState(false);

  // ************ END ************************

  //const [jsonResultsData,setjsonResultsData] = useState({}); // Results Info
  //const [jsonPrevalenceData,setjsonPrevalenceData] = useState({});
  //const [jsonCountData,setJsonCountData] = useState({});
  

  const [openSnackAlert,setOpenSnackAlert] = useState({
    open: false,
    severity: "info",
    title: "",
    msg: "",
  });

  // Which Form will be expanded in Page -> 1: Form1 - 2: Form2 - 3: Form3
  const [formExpanded, setFormExpanded] = useState(1); 

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogStudyOpen, setDialogStudyOpen] = useState(false);
  const [dialogStudyData,setDialogStudyData] = useState({});

  // 0: Prevalence  | 1: Count | 2: Both
  const [essayType, setEssayType] = useState(-1);

  // Tree & State of FOODS
  const [data, setData] = useState(null);
  const [dataAux, setDataAux] = useState([]);

  // Tree & State of AGENTS
  const [selectedAgent, setSelectedAgent] = useState(null);

  // State Variables for Editing 
  const [isReadyToEdit,setIsReadyToEdit] = useState(false);
  const [bacteriaData,setBacteriaData] = useState({});
  const [bacteriaDataAux,setBacteriaDataAux] = useState({});

  const [runOnceEdit,setRunOnceEdit] = useState({
    handleParasite: false,
    handleEssayType: true,
    handleEssayPrevEnum: true,
    foodTree:  true,
  });

  // Default useEffect (get all from APIs)
  useEffect(() => {
    callStudyIdAPI(0);
    callEditDataAPI(0);
    callFoodAPI(0);
    callParasiteAPI(0);
    callStudyStructAPI(0);
  },[]);

  // populate parasite & essay input
  useEffect( () => {
    if (!runOnceEdit.handleParasite) {
      if (Object.keys(jsonAgent).length > 0 && Object.keys(bacteriaData).length > 0) {
        handleParasite(0, bacteriaData["Parasite"]);
        // Call Next Fill Function
        setRunOnceEdit({...runOnceEdit, handleParasite:true, handleEssayType: false})
      }    
    }
  },[isReadyToEdit]);

    // populate essay inputs
    useEffect( () => {
      if (!runOnceEdit.handleEssayType) {
        if (Object.keys(bacteriaData).length > 0) {
          handleEssayType(typeBac());          
          if ("EssaySamplePrep" in bacteriaData) {
            handlePreparation(0, bacteriaData["EssaySamplePrep"])
          }
          if ("EssayIdentificationType" in bacteriaData) {
            handleIdentification(0, bacteriaData["EssayIdentificationType"])
          }
          if ("EssayInfectivityType" in bacteriaData) {
            handleInfectivity(0, bacteriaData["EssayInfectivityType"])
          }
          // Call Next Fill Function
          setRunOnceEdit({...runOnceEdit, handleEssayType:true, handleEssayPrevEnum: false})
        }
      }
    },[isReadyToEdit, jsonAgent]);

    // populate Enum & Prev inputs
    useEffect(() => {
      if (!runOnceEdit.handleEssayPrevEnum) {
        if ("Prevalence" in bacteriaData) {
          if (jsonEssayPrevalence2.length > 0 && Object.keys(bacteriaData).length > 0) {
            handleEssayPrevalence2FillFromEdit(0, bacteriaData["Prevalence"]["EssayDetType"])
            setRunOnceEdit({...runOnceEdit, handleEssayPrevEnum:true})
          }
        }

        if ("Count" in bacteriaData) {
          if (jsonEssayEnum2.length > 0 && Object.keys(bacteriaData).length > 0) {
            handleEssayEnum2FillFromEdit(0, bacteriaData["Count"]["EssayEnumType"])
          }
        }

        // Call Next Fill Function
        setRunOnceEdit({...runOnceEdit, handleEssayPrevEnum:true, foodTree: false})
      }
    },[runOnceEdit]);

    // populate Food Tree
    useEffect( () => {
      if (!runOnceEdit.foodTree) {
        if (dataAux.length > 0 && Object.keys(bacteriaDataAux).length > 0) {
          let bacData = cloneDeep(bacteriaDataAux);
          let alreadyHave = false;
          dataAux.map((json,index) => {
            if ([json.label] in bacteriaDataAux) {
              alreadyHave = true;
              handleChange( json.label, index, bacData[json.label] ); 
              delete bacData[json.label];
              return setBacteriaDataAux(bacData);
            }
          });
          if (alreadyHave == false) {
            setRunOnceEdit({...runOnceEdit, foodTree: true});
          }
        }
      }
    },[runOnceEdit, bacteriaData,dataAux, bacteriaDataAux]);



  const typeBac = () => {
    if ("Prevalence" in bacteriaData && "Count" in bacteriaData) {
      return "2"
    } else if ("Count" in bacteriaData) {
      return "1"
    } else if ("Prevalence" in bacteriaData) {
      return "0"
    } else {
      return ""
    }
  }

  const setDialogErrorMaxAttempts = () => {
    setDialogData({
      open: true,
      type: "error",
      loading: false,
      title: "Error",
      msg: Texts.dialog_errors.max_attempts,
    });
  }

  const callEditDataAPI = (attempt) => {
    api
      .get("/parasite/manage/edit", {params: {id: parasite_id}, headers: {authorization: authToken}})
      .then((response) => {
        setBacteriaData(response.data);
        setBacteriaDataAux(response.data);
        
        return fillEditForm(response.data);
      }).catch(err => {
        if (attempt >= 2) {
          try {
            return setDialogErrorMaxAttempts(err.response.data.error);
          } catch {
            return setDialogErrorMaxAttempts();
          }
        } else {
          callEditDataAPI(attempt+1);
        }
      });
  }

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const fillEditForm = async (data) => {
    // form1Ref.current.getFieldValue("StudyID");
    // form1Ref.current.getFieldRef("StudyID");
    
    setOpenSnackAlert({
      open: true,
      severity: "info",
      title: "LOADING",
      msg: "Loading Data..."
    });

    await timeout(1000); //for 1 sec delay
    // Fill Tree Selects


    setIsReadyToEdit(true);
  }

  const callParasiteAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonAgent([]);
      setJsonAgentData({});
      //setjsonResultsData({});
      //setjsonPrevalenceData({});
      //setJsonCountData({});
      return ;
    }

    // Run API Call
    api.get('/parasite/struct/all').then(response => {
      //console.log("recebeu:", response.data);
      if (!response.data) {
          //console.log("entrou aqui");
      }

      // MAIN Tree Agent (Parasite)
      setJsonAgentTree(response.data.parasite_tree);
      setJsonAgent([response.data.parasite_tree]);
      //console.log("TOMA:",[response.data.parasite_tree])

      // Another Trees (SubTrees)
      setJsonSubtypeTree(response.data.subtype_tree);
      setJsonEssaySampleTree(response.data.essay_sample_tree);
      setJsonEssayIdentificationTree(response.data.essay_identification_tree);     
      setJsonEssayInfectivityTree(response.data.essay_infectivity_tree);
      setJsonEssayPrevEnumTree(response.data.prev_enum_tree);

      // Data Fields
      setJsonAgentData(response.data.parasite_data);

      // STEP 3 -> Prevalence/General Block 
      setJsonPrevalenceOnlyResultsData(response.data.prev_results_data);
      setJsonParasiteUnitTree(response.data.prev_enum_results_data);
      setJsonPrevalenceResultsData(response.data.results_data);

      // STEP 3 -> Enumeration Block 
      setJsonEnumResultsData(response.data.prev_enum_results_infectivity_data);

      // STEP 3 -> Infectivity Block
      setJsonInfectivityResultsData(response.data.results_infectivity_data);


      setJsonFullStruct(response.data.full_structure);
      //setJsonSubtype(response.data.subtype_tree);
      // ***************************************************************************************************
      //setjsonResultsData(response.data.results_data);
      //setjsonPrevalenceData(response.data.prevalence_data);
      //setJsonCountData(response.data.count_data);
      //setDataFullStruct(response.data.full_structure);
      return;
    }).catch(err => {
      callParasiteAPI(attempt+1);
    });

  };

  const callFoodAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setDataAux([]);
      setJsonFoods(null);
      setJsonFoodCharacs({});
      return ;
    }
  
    // Run API Call
    api.get('/food/struct/all_parasite').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonFoods(response.data.food_expand_parasite_tree);
        setDataAux([response.data.food_expand_parasite_tree]);
        setJsonFoodCharacs(response.data.food_data);
        setJsonFoods(response.data.food_data);
        //console.log("RES: ",Object.keys(response.data));
        
        return;
    }).catch(err => {
        callFoodAPI(attempt+1);
    });
  };

  const callStudyIdAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudies([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/list/id_only').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonStudies(response.data);
        return;
    }).catch(err => {
        callStudyIdAPI(attempt+1);
    });
  }

  const callStudyStructAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudyStruct([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/struct/study_data').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonStudyStruct(response.data);
        return;
    }).catch(err => {
        callStudyStructAPI(attempt+1);
    });
  }

  const callGetStudyInfoAPI = () => {
    try {
      let getStudyID = form1Ref.current.getFieldValue("StudyID");
      if (getStudyID == "") {
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: "No StudyID selected"
        });
      } else {
        // Run API Call
        api.get('/study/list/id',{params: {StudyID: getStudyID}, headers: {authorization: authToken}})
        .then((response) => {
          //console.log("recebeu:", response.data);
          setDialogStudyData(response.data);
          setDialogStudyOpen(true);
          return;
        }).catch(err => {
  
        });
      }
    } catch (err) {
      //console.log(err);
    }
  }

  const removeNullFields = (obj) => {
    Object.entries(obj).forEach(([key, val])  =>
      (val && typeof val === 'object') && removeNullFields(val) ||
      (val === null || val === "" || val === "---") && delete obj[key]
    );
    return obj;
  };

  async function handleSubmitFormStudy(data, {reset}, event) {
    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = formStudyRef.current.getFieldRef(value).current || formStudyRef.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formStudyRef.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (formStudyRef.current.getFieldRef(value).required) {
              formStudyRef.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      callUpdateStudyAPI(data);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  function setIsMidProcessing(event) {
    if (event.target.value == "MidProcessing") {
        setIsMeatMidProcessing({...isMeatMidProcessing, midproc: true});
    } else {
        setIsMeatMidProcessing({...isMeatMidProcessing, midproc: false});
    }
  }
  
  async function handleSubmitForm1(data, { reset }, event) {

    // Condition -> if no infectivy has selected in first form -> hide infectivy results in third form
    try {
      if (data.EssayInfectivityType === "---") {
        setIsInfectivitySelected(false);
      } else {
        setIsInfectivitySelected(true);
      }
    } catch (err) {

    }

    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = form1Ref.current.getFieldRef(value).current || form1Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form1Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form1Ref.current.getFieldRef(value).required) {
              form1Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      // Set Form1 Data
      setFormData({...formData, form1: data });
      // Open Form2
      setFormExpanded(2);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm2(data, { reset }, event) {
    // Condition -> if molluscs has selected in second form (food category) -> show dropdown "AreResultsExtrapolated" in third form
    try {
      if (data.SubCategory === "Molluscs") {
        setIsMolluscsSelected(true);
      } else {
        setIsMolluscsSelected(false);
      }
    } catch (err) {

    }   

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form2Ref.current.getFieldRef(value).current || form2Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form2Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form2Ref.current.getFieldRef(value).required) {
              form2Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      setFormData({...formData, form2: data });
      // Open Form3
      setFormExpanded(3);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm3(data, { reset }, event) {

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form3Ref.current.getFieldRef(value).current || form3Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form3Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form3Ref.current.getFieldRef(value).required) {
              form3Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      let finalData =  cloneDeep({...formData.form1, ...formData.form2, ...data });
      let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(finalData).map((key,value) => {
        //} else if (key in data_formatted) {
        //  data_formatted[key] = finalData[key];
        if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = finalData[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = finalData[key];
        } else {
          data_formatted[key] = finalData[key];
        }
      });

      if ('essay' in data_formatted) {
        delete data_formatted['essay']; 
      }
      
      data_formatted = removeNullFields(data_formatted);
      
      // Set Form3 Data
      setFormData({...formData, form3: data });
      // Call Backend For Register
      //setFormExpanded(3);
      //let finalData = prepareStudyJson(formData.form1, formData.form2, data);
      
      //console.log("Final:",data_formatted);
      if (Object.keys(data_formatted['Prevalence']).length === 0) {
        delete data_formatted['Prevalence'];
      } else if (Object.keys(data_formatted['Count']).length === 0) {
        delete data_formatted['Count'];
      }


      data_formatted = {"_id": bacteriaData["_id"], ...data_formatted,  }
      //console.log("Final2:",data_formatted);

      callEditAPI( data_formatted );
      setOpenSnackAlert({...openSnackAlert, msg: "Loading..."});
      //setDialogOpen(true);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5NzA2OTQwMiwiZXhwIjoxNTk3MzI4NjAyfQ.Ya6a6vW1fKLu0lhQom37XlJ9KeZfEJECWAkDbLJ5gaM";


  const callUpdateStudyAPI = (data) => {
    api.post('/study/manage/update', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      //console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.update_sucess
      });

      return setDialogStudyOpen(false);

    }).catch(function (error) {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.update_error
      });
    });
  }

  const callEditAPI = (data) => {

    api.post('/parasite/manage/edit', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      ////console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.update_sucess
      });

      setFormExpanded(0);
      return setIsReadyToEdit(false);

    }).catch(function (error) {
      ////console.log("ERROR");

      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.update_error
      });

    });
  }
  

  const handleBackForm2 = () => {
    setFormData({...formData, form1: null, form2: null });
    // Open Form2
    setFormExpanded(1);
  }

  const handleBackForm3 = () => {
    setFormData({...formData, form2: null, form3: null });
    // Open Form2
    setFormExpanded(2);
  }

  const clearAgent = () => {
    setDialogOpen(false);

    setFormData({ form1: null, form2: null, form3: null });
    setFormExpanded(1);

    setData(null);
    setDataAux([jsonFoods]);

    setEssayType(-1);
    setSelectedAgent(null);

    // Clear Form1 (Without STUDYID FIELD)
    Object.keys(formData.form1).map((value,index) => {
      if (value !== "StudyID") {
        form1Ref.current.clearField(value);
        ////console.log(formRef.current.getFieldRef(value));
  
        let isNativeSelect = form1Ref.current.getFieldRef(value).current || true;
        let isAutoComplete = form1Ref.current.getFieldRef(value).props || true;
  
        if (typeof isNativeSelect != 'boolean') {
          ////console.log("NativeSelect");
          form1Ref.current.getFieldRef(value)['current']['value'] = "";
        }
  
        if (typeof isAutoComplete != 'boolean') {
          ////console.log("AutoCompelte");
          form1Ref.current.getFieldRef(value)['state']['value'] = "";
        }
      }
    });

    // Clear Form2
    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    // Clear Form3
    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

  }

  const clearFood = () => {
    setDialogOpen(false);

    setFormData({...formData, form2: null, form3: null });
    setFormExpanded(2);

    setData(null);
    setDataAux([jsonFoods]);

    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

  }

  const clearEndFull = () => {
    setDialogOpen(false);

    setFormData({form1: null, form2: null, form3: null });
    setFormExpanded(1);

    setData(null);
    setDataAux([jsonFoods]);

    setEssayType(-1);
    setSelectedAgent(null);

    try {
    // Clear Form1 (Without STUDYID FIELD)
    Object.keys(formData.form1).map((value,index) => {
      form1Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form1Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form1Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form1Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form1Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });
    } catch (err) {
      //console.log("ERRO1:",err);
    }

    try {
    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });
    } catch (err) {
      //console.log("ERRO2:",err);
    }

    try {
    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });
    } catch (err) {
      //console.log("ERRO3:",err);
    }

  }
  
  async function handleSubmit(data, { reset }, event) {
    ////console.log(Yup.object());
    //event.preventDefault();
    //event.stopPropagation();
    //event.nativeEvent.stopImmediatePropagation();

    //console.log(data);

    let resErrors = {}
    Object.keys(data).map((value,index) => {
        ////console.log(value,"->",formRef.current.getFieldRef(value));
        ////console.log(typeof formRef.current.getFieldRef(value));
        ////console.log(value,"->",);
        ////console.log("-----");

        let isTextField = formRef.current.getFieldRef(value).current || formRef.current.getFieldRef(value).props || true;
        ////console.log(value," -> ",isTextField);

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        } else {
          if (data[value] === "") {
            if (formRef.current.getFieldRef(value).required) {
              formRef.current.setFieldError(value, true);
              resErrors[value] = Yup.string().required();
            }
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        }
        

       /* if (typeof isTextField != 'boolean') {
          if (data[value] == "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        } else {
        }*/
    });
  }

  const handleParasite = (index, event) => {
    if (event != "null") {
      if (index==0) {
        //setJsonEssayCount(jsonAgentTree[event].count);
        //setDataAuxEssayCount([jsonAgentTree[event].count]);
        //setJsonEssayPrevalence(jsonAgentTree[event].prevalence);
        //setDataAuxEssayPrevalence([jsonAgentTree[event].prevalence]);
        setSelectedAgent(event);
  
        /* Parasite Specific Data */
        setJsonEssaySample([jsonEssaySampleTree[event]]);
        setJsonSubtype(jsonSubtypeTree[event]);
        setJsonSubtypeAux([jsonSubtypeTree[event]]);
        setJsonEssayIdentification([jsonEssayIdentificationTree[event]]);
        setJsonEssayInfectivity([jsonEssayInfectivityTree[event]]);
        setJsonParasiteUnit(jsonParasiteUnitTree[event]);
      }

      if (jsonAgent[index][event] != null) {
        let newData = jsonAgent;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        setJsonAgent(newData);
      } else {
        let newData = jsonAgent;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setJsonAgent(newData);
      }
    } else {
      if (index == 0) {
        setEssayType(-1);
        setSelectedAgent(null);
  
        //setJsonEssayCount(null);
        //setDataAuxEssayCount([null]);
  
        /* Parasite Specific Data */
        setJsonEssaySample([]);
        setJsonSubtype(null);
        setJsonSubtypeAux([]);
        setJsonEssayIdentification([]);
        setJsonEssayInfectivity([]);
      }

      let newData = jsonAgent;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setJsonAgent(newData);
    }
  };

  const handleEssayEnum2 = (index, event) => {
    if (event != "null") {
      if (jsonEssayEnum2[index][event] != null) {
        let newData = jsonEssayEnum2;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        setJsonEssayEnum2(newData);
      } else {
        let newData = jsonEssayEnum2;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setJsonEssayEnum2(newData);
      }
    } else {
      let newData = jsonEssayEnum2;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setJsonEssayEnum2(newData);
    }
  }
  
  const handleEssayPrevalence2FillFromEdit = (index, event) => {
    let simulate = jsonEssayPrevalence2

    const ks = []
    if ("EssayDet" in bacteriaData["Prevalence"]) {
      ks.push("EssayDet")
    }

    while(ks.length > 0) {
      try {
        if (event != "null") {
          if (simulate[index][event] != null && !Array.isArray(simulate[index][event])) {
            let newData = simulate;
            newData = newData.slice(0, index + 1);
            newData[index]["selected"] = event;
            newData.push(newData[index][event]);
            newData[index][event]["selected"] = "null";
            simulate = newData
          } else {
            let newData = simulate;
            newData = newData.slice(0, index + 1);
            newData[index]["selected"] = event;
            simulate = newData
          }
        } else {
          let newData = simulate;
          newData[index]["selected"] = "null";
          newData = newData.slice(0, index + 1);
          simulate = newData;
        }
      } catch (err) {

      }
      event = bacteriaData["Prevalence"][ks[index]]
      index = index+1
      ks.pop()
    }
    
    try {
      if (event != "null") {
        if (simulate[index][event] != null && !Array.isArray(simulate[index][event])) {
          let newData = simulate;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          newData.push(newData[index][event]);
          newData[index][event]["selected"] = "null";
          simulate = newData
        } else {
          let newData = simulate;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          if (newData[index][event] !== null) {
            newData.push(newData[index][event])
          }
          simulate = newData
        }
      } else {
        let newData = simulate;
        newData[index]["selected"] = "null";
        newData = newData.slice(0, index + 1);
        simulate = newData;
      }
    } catch (err) {
      
    }

    setJsonEssayPrevalence2(simulate)
  }

  const handleEssayEnum2FillFromEdit = (index, event) => {
    let simulate = jsonEssayEnum2

    const ks = []
    if ("EssayEnum" in bacteriaData["Count"]) {
      ks.push("EssayEnum")
    }

    while(ks.length > 0) {
      try {
        if (event != "null") {
          if (simulate[index][event] != null && !Array.isArray(simulate[index][event])) {
            let newData = simulate;
            newData = newData.slice(0, index + 1);
            newData[index]["selected"] = event;
            newData.push(newData[index][event]);
            newData[index][event]["selected"] = "null";
            simulate = newData

          } else {

            let newData = simulate;
            newData = newData.slice(0, index + 1);
            newData[index]["selected"] = event;
            simulate = newData
          }
        } else {
          let newData = simulate;
          newData[index]["selected"] = "null";
          newData = newData.slice(0, index + 1);
          simulate = newData;
        }
      } catch (err) {

      }
      event = bacteriaData["Count"][ks[index]]
      index = index+1
      ks.pop()
    }
    try {
      if (event != "null") {
        if (simulate[index][event] != null && !Array.isArray(simulate[index][event])) {
          let newData = simulate;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          newData.push(newData[index][event]);
          newData[index][event]["selected"] = "null";
          simulate = newData

        } else {

          let newData = simulate;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          if (newData[index][event] !== null) {
            newData.push(newData[index][event])
          }
          simulate = newData
        }
      } else {
        let newData = simulate;
        newData[index]["selected"] = "null";
        newData = newData.slice(0, index + 1);
        simulate = newData;
      }
    } catch (err) {

    }

    setJsonEssayEnum2(simulate)
  }

  const handleEssayPrevalence2 = (index, event) => {
    try {
      if (event != "null") {
        if (jsonEssayPrevalence2[index][event] != null) {
          let newData = jsonEssayPrevalence2;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          newData.push(newData[index][event]);
          newData[index][event]["selected"] = "null";
          setJsonEssayPrevalence2(newData);
        } else {
          let newData = jsonEssayPrevalence2;
          newData = newData.slice(0, index + 1);
          newData[index]["selected"] = event;
          setJsonEssayPrevalence2(newData);
        }
      } else {
        let newData = jsonEssayPrevalence2;
        newData[index]["selected"] = "null";
        newData = newData.slice(0, index + 1);
        setJsonEssayPrevalence2(newData);
      }
    } catch (err) {
      
    }
  }

  const handlePreparation = (index, event) => {
    if (event != "null") {
      if (jsonEssaySample[index][event] != null) {
        let newData = jsonEssaySample;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        setJsonEssaySample(newData);
      } else {
        let newData = jsonEssaySample;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setJsonEssaySample(newData);
      }
    } else {
      let newData = jsonEssaySample;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setJsonEssaySample(newData);
    }
  };

  const handleInfectivity = (index, event) => {
    if (event != "null") {
      if (jsonEssayInfectivity[index][event] != null) {
        let newData = jsonEssayInfectivity;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        setJsonEssayInfectivity(newData);
      } else {
        let newData = jsonEssayInfectivity;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setJsonEssayInfectivity(newData);
      }
    } else {
      let newData = jsonEssayInfectivity;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setJsonEssayInfectivity(newData);
    }
  };

  const handleIdentification = (index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      if (jsonEssayIdentification[index][event] != null) {
        let newData = jsonEssayIdentification;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        setJsonEssayIdentification(newData);
      } else {
        let newData = jsonEssayIdentification;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setJsonEssayIdentification(newData);
      }
    } else {
      let newData = jsonEssayIdentification;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setJsonEssayIdentification(newData);
    }
  };


  const handleChange = (label, index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (label == "Category") {
      if (event == "Meat") {
        setIsMeatMidProcessing({...isMeatMidProcessing, meat: true});
      } else {
        setIsMeatMidProcessing({...isMeatMidProcessing, meat: false});
      }
    }

    if (event != "null") {
      setData({ ...data, [label]: event });

      if (dataAux[index][event] != null) {
        let newData = dataAux;
        newData = newData.slice(0, index + 1);

        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";

        setDataAux(newData);
        /*} else {
            //console.log("Tentou Alterar");
          }*/
      } else {
        let newData = dataAux;
        ////console.log("Entrada: ",newData);
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        ////console.log("Saida: ",newData);
        setDataAux(newData);
      }
    } else {
      ////console.log("entrou1")
      let newData = dataAux;
      ////console.log("Entrada: ",newData);
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      ////console.log("Saida: ",newData);
      setDataAux(newData);
    }
  };

  const handleEssayType = (event) => {
    //console.log(selectedAgent);
    //console.log("entrou");
    //setJsonEssayPrevEnum(event);
    if (event == 0) {
      setJsonEssayPrevalence2([jsonEssayPrevEnumTree[selectedAgent]["EssayDetType"]])
    }
    else if (event == 1) {
      setJsonEssayEnum2([jsonEssayPrevEnumTree[selectedAgent]["EssayEnumType"]])
    }
    else if (event == 2) {
      setJsonEssayPrevalence2([jsonEssayPrevEnumTree[selectedAgent]["EssayDetType"]])
      setJsonEssayEnum2([jsonEssayPrevEnumTree[selectedAgent]["EssayEnumType"]])
    }

    if (event === "") {
      setEssayType(null);
    } else {
      setEssayType(event);
    }

    //console.log(event);
  };

  //const [jsontree,setJsonTree] = useState(jsonFoods);

  const clearData = () => {
    setDataAux([jsonFoods]);
    //setJsonTree(jsonFoods);
    setData(null);
  };

  return (
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Accordion defaultExpanded={true} expanded={formExpanded === 1} className={formExpanded === 1 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Grid container alignItems="center">
            {formData.form1 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"1. Study, Agent & Essay"}</Typography>
        </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form1Ref} className={classes.formWide} onSubmit={handleSubmitForm1}>
        {isReadyToEdit && (
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Study Info</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <Grid container direction="row">
          <Grid item container direction="column">
          <CustomSelect
            label={"Select Study ID" + required_text}
            fullW
            name={"StudyID"}
            defaultValue={"StudyID" in bacteriaData ? bacteriaData["StudyID"] : ""}
            items={jsonstudies}
            labelError={blank_text_error}
            clearable={true}
          />
          </Grid>
          <Grid item>
          <Button
            onClick={() => callGetStudyInfoAPI()}
            variant="contained"
            size="large"
            //disabled={rows.length == 0 ? true : false}
            className={classes.customBTNNextRegister}
          >
            Study Info
          </Button>
          </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Agent Info</Typography>
                <Divider />
                <Paper className={fixedHeightPaper}>
                { 
                  (jsonAgent.map((json,index) => {
                    return (
                    <MaterialNativeSelect
                      key={json.label}
                      label={json.label + required_text}
                      labelError={blank_text_error}
                      defaultValue={[json.label] in bacteriaData ? bacteriaData[json.label] : ""}
                      onChange={(event) => handleParasite(index,event.target.value)}
                      name={json.label}
                    >
                      <option value={""}>Select</option >
                      {Object.keys(json).map(key => 
                        key != "label" && key != "selected" ? (
                          <option key={key} value={key}>
                            {key}
                          </option >
                        ) : null
                      )}
                    </MaterialNativeSelect>
                  )}))
                }
                
                {selectedAgent ?
                  jsonSubtypeAux.map((array_obj) => {
                    return array_obj.map((json,index) => {
                      return json.data == null 
                      ?
                      <MaterialInput
                          key={json.name}
                          name={json.name}
                          label={json.label + (json.required ? required_text : blank_text)}
                          defaultValue={[json.name] in bacteriaData ? bacteriaData[json.name] : ""}
                          type={json.type || "text"}
                          isrequired={json.required}
                          labelError={blank_text_error}
                          placeholder={"enter text..."}
                      />
                      :
                      <MaterialNativeSelect
                      key={json.name}
                      label={json.label + (json.required ? required_text : blank_text)}
                      defaultValue={[json.name] in bacteriaData ? bacteriaData[json.name] : ""}
                      labelError={blank_text_error}
                      name={json.name}
                      >
                      <option value={"---"}>Select</option >
                      {json.data.map((dataValue) =>
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                      )}
                      </MaterialNativeSelect>
                    })
                  })
                : null}

                { 
                Object.entries(jsonAgentData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                  defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={key in bacteriaData ? bacteriaData[key] : ""}
                defaultValue={""}
                labelError={blank_text_error}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                <option key={dataValue} value={dataValue}>
                  {dataValue}
                </option >
                )}
                </MaterialNativeSelect>
                })}

                </Paper>
              </Grid>

        { selectedAgent ?
          <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Type</Typography>
              <Divider />
              <Paper className={fixedHeightPaper}>
              <MaterialNativeSelect
                  name="essay"
                  label={"Select Essay"  + required_text}
                  labelError={blank_text_error}
                  defaultValue={bacteriaData ? typeBac() : ""}
                  onChange={e => handleEssayType(e.target.value)}
                >
                  <option value={""}>Select</option>
                  <option value={"0"}>Prevalence</option>
                  <option value={"1"}>Enumeration</option>
                  <option value={"2"}>Prevalence+Enumeration</option>
                </MaterialNativeSelect>
              </Paper>
          </Grid>
          :null }

    {selectedAgent ?
    <Grid item xs={12} md={12}>
    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Sample Preparation</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
            (jsonEssaySample.map((json,index) => (
              json.length > 0
              ?
                json.map(value => (
                  value.data == null 
                  ? 
                      <MaterialInput
                      key={value.name}
                      name={value.name}
                      label={value.label + (value.required ? required_text : blank_text)}
                      type={value.type || "text"}
                      isrequired={value.required}
                      defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                  : 
                    <MaterialNativeSelect
                    key={value.name}
                    label={value.label}
                    defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                    labelError={blank_text_error}
                    name={value.name}
                    >
                    <option value={""}>Select</option >
                    {value.data.map((dataValue) =>
                        <option key={dataValue} value={dataValue}>
                          {dataValue}
                        </option >
                    )}
                    </MaterialNativeSelect>
                ))
              :
                <MaterialNativeSelect
                  key={json.label}
                  label={json.label + blank_text}
                  labelError={blank_text_error}
                  onChange={(event) => handlePreparation(index,event.target.value)}
                  name={json.label}
                  defaultValue={[json.label] in bacteriaData ? bacteriaData[json.label] : ""}
                >
                  <option value={"---"}>Select</option >
                  {Object.keys(json).map(key => 
                    key != "label" && key != "selected" ? (
                      <option key={key} value={key}>
                        {key}
                      </option >
                    ) : null
                  )}
                </MaterialNativeSelect>
            )))
        }
        </Paper>
    </Grid> 
    : null}

    { essayType == 2 || essayType == 0 ?
    <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Prevalence</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          { 
          (jsonEssayPrevalence2.map((json,index) => (
            json.length > 0
            ?
              json.map(value => (
                value.data == null 
                ? 
                    <MaterialInput
                    key={value.name}
                    name={value.name}
                    label={value.label + (value.required ? required_text : blank_text)}
                    defaultValue={"Prevalence" in bacteriaData ? [value.name] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][value.name] : "" : ""}
                    type={value.type || "text"}
                    isrequired={value.required}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                  />
                : 
                  <MaterialNativeSelect
                  key={value.name}
                  label={value.label}
                  defaultValue={"Prevalence" in bacteriaData ? [value.name] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][value.name] : "" : ""}
                  labelError={blank_text_error}
                  name={value.name}
                  >
                  <option value={""}>Select</option >
                  {value.data.map((dataValue) =>
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                  )}
                  </MaterialNativeSelect>
              ))
            :
              "data" in json && "required" in json
              ?
              <MaterialInput
                  key={json.name}
                  name={json.name}
                  label={json.label + (json.required ? required_text : blank_text)}
                  defaultValue={"Prevalence" in bacteriaData ? [json.name] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][json.name] : "" : ""} 
                  type={json.type || "text"}
                  isrequired={json.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
              />
              : 
                <MaterialNativeSelect
                  key={json.label}
                  label={json.label + required_text}
                  labelError={blank_text_error}
                  onChange={(event) => handleEssayPrevalence2(index,event.target.value)}
                  defaultValue={"Prevalence" in bacteriaData ? [json.label] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][json.label] : "" : ""}
                  name={json.label}
                >
                  <option value={""}>Select</option >
                  {Object.keys(json).map(key => 
                    key != "label" && key != "selected" ? (
                      <option key={key} value={key}>
                        {key}
                      </option >
                    ) : null
                  )}
                </MaterialNativeSelect>
          )))
          }
          </Paper>
        </Grid>
    : null}

    { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Enumeration</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
        (jsonEssayEnum2.map((json,index) => (
          json.length > 0
          ?
            json.map(value => (
              value.data == null 
              ? 
                  <MaterialInput
                  key={value.name}
                  name={value.name}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  defaultValue={"Count" in bacteriaData ? [value.name] in bacteriaData["Count"] ? bacteriaData["Count"][value.name] : "" : ""}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
              : 
                <MaterialNativeSelect
                key={value.name}
                label={value.label}
                defaultValue={"Count" in bacteriaData ? [value.name] in bacteriaData["Count"] ? bacteriaData["Count"][value.name] : "" : ""}
                labelError={blank_text_error}
                name={value.name}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            ))
          :
            <MaterialNativeSelect
            key={json.label}
            label={json.label + required_text }
            labelError={blank_text_error}
            onChange={(event) => handleEssayEnum2(index,event.target.value)}
            defaultValue={"Count" in bacteriaData ? [json.label] in bacteriaData["Count"] ? bacteriaData["Count"][json.label] : "" : ""}
            name={json.label}
            >
              <option value={""}>Select</option>
              {Object.keys(json).map(key => 
                key != "label" && key != "selected" ? (
                  <option key={key} value={key}>
                    {key}
                  </option >
                ) : null
              )}
            </MaterialNativeSelect>
        )))
        }
        </Paper>
        </Grid>
        : null}

      {selectedAgent ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Identification</Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
            { 
              (jsonEssayIdentification.map((json,index) => (
                json.length > 0
                ?
                  json.map(value => (
                    value.data == null 
                    ? 
                        <MaterialInput
                        key={value.name}
                        name={value.name}
                        label={value.label + (value.required ? required_text : blank_text)}
                        type={value.type || "text"}
                        defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                        isrequired={value.required}
                        labelError={blank_text_error}
                        placeholder={"enter text..."}
                      />
                    : 
                      <MaterialNativeSelect
                      key={value.name}
                      label={value.label}
                      defaultValue={""}
                      defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                      labelError={blank_text_error}
                      name={value.name}
                      >
                      <option value={""}>Select</option >
                      {value.data.map((dataValue) =>
                          <option key={dataValue} value={dataValue}>
                            {dataValue}
                          </option >
                      )}
                      </MaterialNativeSelect>
                  ))
                :
                  <MaterialNativeSelect
                      key={json.label}
                      label={json.label + blank_text}
                      labelError={blank_text_error}
                      onChange={(event) => handleIdentification(index,event.target.value)}
                      defaultValue={[json.label] in bacteriaData ? bacteriaData[json.label] : ""}
                      name={json.label}
                  >
                      <option value={"---"}>Select</option >
                      {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                          <option key={key} value={key}>
                          {key}
                          </option >
                      ) : null
                      )}
                  </MaterialNativeSelect>
              )))
            }
            </Paper>
        </Grid> 
        : null}

    {selectedAgent ?
    <Grid item xs={12} md={12}>
    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Infectivity</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
            (jsonEssayInfectivity.map((json,index) => (
              json.length > 0
              ?
                json.map(value => (
                  value.data == null 
                  ? 
                      <MaterialInput
                      key={value.name}
                      name={value.name}
                      label={value.label + (value.required ? required_text : blank_text)}
                      type={value.type || "text"}
                      defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                      isrequired={value.required}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                  : 
                    <MaterialNativeSelect
                    key={value.name}
                    label={value.label}
                    defaultValue={[value.name] in bacteriaData ? bacteriaData[value.name] : ""}
                    labelError={blank_text_error}
                    name={value.name}
                    >
                    <option value={""}>Select</option >
                    {value.data.map((dataValue) =>
                        <option key={dataValue} value={dataValue}>
                          {dataValue}
                        </option >
                    )}
                    </MaterialNativeSelect>
                ))
              :
                <MaterialNativeSelect
                  key={json.label}
                  label={json.label + blank_text}
                  labelError={blank_text_error}
                  onChange={(event) => handleInfectivity(index,event.target.value)}
                  defaultValue={[json.label] in bacteriaData ? bacteriaData[json.label] : ""}
                  name={json.label}
                >
                  <option value={"---"}>Select</option >
                  {Object.keys(json).map(key => 
                    key != "label" && key != "selected" ? (
                      <option key={key} value={key}>
                        {key}
                      </option >
                    ) : null
                  )}
                </MaterialNativeSelect>
            )))
        }
        </Paper>
    </Grid> 
    : null}

        <Grid item xs={12} md={12} container justify="flex-end">
        <Button
            onClick={() => form1Ref.current.submitForm()}
            variant="contained"
            size="large"
            className={classes.customBTNNextRegister}
          >
            Next
        </Button>
        </Grid>

        </Grid>
        )}
        </Form>

        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 2} className={formExpanded === 2 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form2 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"2. Food"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form2Ref} className={classes.formWide} onSubmit={handleSubmitForm2}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Info
          </Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          {"FoodOrigin" in bacteriaData &&
                    <CountrySelect 
                    name={"FoodOrigin"} 
                    label={"Food Origin" + required_text}
                    defaultValue={"FoodOrigin" in bacteriaData ? bacteriaData["FoodOrigin"] : ""}
                    labelError={blank_text_error} />
          }

            { 
                (dataAux.map((json,index) => {
                  return (
                  <MaterialNativeSelect
                    key={json.label}
                    label={json.label + required_text}
                    labelError={blank_text_error}
                    onChange={(event) => handleChange(json.label,index,event.target.value)}
                    defaultValue={ [json.label] in bacteriaData ? bacteriaData[json.label] : "" }
                    name={json.label}
                  >
                    <option value={""}>Select</option >
                    {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                        <option key={key} value={key}>
                          {key}
                        </option >
                      ) : null
                    )}
                  </MaterialNativeSelect>
                )}))
            }
            {/*<Button onClick={clearData}>Clear Data</Button>*/}
          </Paper>
        </Grid>
          
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Characteristics
          </Typography>
          <Divider />
          {/*//console.log("RODOU")*/}
          <Paper className={fixedHeightPaper}>
          { 
                Object.entries(jsonFoodCharacs).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                labelError={blank_text_error}
                onChange={key == "Stage" ? (e) => setIsMidProcessing(e) : null}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })}
            {isMeatMidProcessing.meat && isMeatMidProcessing.midproc && (
                <MaterialNativeSelect
                key={"OrganSampled"}
                label={"OrganSampled" + required_text}
                defaultValue={"OrganSampled" in bacteriaData ? bacteriaData["OrganSampled"] : ""}
                labelError={blank_text_error}
                name={"OrganSampled"}
                >
                  <option value={""}>Select</option >
                  <option value={"Diaphragm"}>Diaphragm</option>
                  <option value={"Meat juice"}>Meat juice</option>
                  <option value={"Liver"}>Liver</option>
                  <option value={"Heart"}>Heart</option>
                </MaterialNativeSelect>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm2()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form2Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                Next
              </Button>
        </Grid>
        </Grid>
        </Form>
        </AccordionDetails>
        </Accordion>      
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 3} className={formExpanded === 3 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form3 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"3. Results"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form3Ref} className={classes.formWide} onSubmit={handleSubmitForm3}>

        <Grid container spacing={3}>

        <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>General Results</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          
          {
            Object.entries(jsonParasiteUnit).map(([key, value]) => {
              return value.data == null 
              ?
            <MaterialInput
              key={key}
              name={key}
              label={value.label + (value.required ? required_text : blank_text)}
              type={value.type || "text"}
              defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
              isrequired={value.required}
              labelError={blank_text_error}
              placeholder={"enter text..."}
            />
            :
            <MaterialNativeSelect
            key={key}
            label={value.label + (value.required ? required_text : blank_text)}
            defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
            labelError={blank_text_error}
            name={key}
            >
            <option value={""}>Select</option >
            {value.data.map((dataValue) =>
              dataValue != "label" && dataValue != "selected" ? (
                <option key={dataValue} value={dataValue}>
                  {dataValue}
                </option >
              ) : null
            )}
            </MaterialNativeSelect>
            })
          }

          {
            Object.entries(jsonPrevalenceResultsData).map(([key, value]) => {
              return key === "AreResultsExtrapolated" && !isMolluscsSelected  // check if molluscs was selected and current item is AreResultsExtrapolated
                ? null
                : value.data == null 
                  ?
                    <MaterialInput
                      key={key}
                      name={key}
                      label={value.label + (value.required ? required_text : blank_text)}
                      type={value.type || "text"}
                      defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                      isrequired={value.required}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                  :
                    <MaterialNativeSelect
                    key={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                    labelError={blank_text_error}
                    name={key}
                    >
                    { value.required
                        ? <option value={""}>Select</option >
                        : <option value={"---"}>Select</option >
                    }
                    {value.data.map((dataValue) =>
                      dataValue != "label" && dataValue != "selected" ? (
                        <option key={dataValue} value={dataValue}>
                          {dataValue}
                        </option >
                      ) : null
                    )}
                    </MaterialNativeSelect>
                    })
          }
          </Paper>
        </Grid>

        { essayType == 0 || essayType == 2 ?
        <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Prevalence Results</Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
            {
              Object.entries(jsonPrevalenceOnlyResultsData).map(([key, value]) => {
                return value.data == null 
                  ?
                    <MaterialInput
                      key={key}
                      name={key}
                      label={value.label + (value.required ? required_text : blank_text)}
                      defaultValue={"Prevalence" in bacteriaData ? [key] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][key] : "" : ""}
                      type={value.type || "text"}
                      isrequired={value.required}
                      labelError={blank_text_error}
                      placeholder={"enter text..."}
                    />
                  :
                    <MaterialNativeSelect
                    key={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    defaultValue={"Prevalence" in bacteriaData ? [key] in bacteriaData["Prevalence"] ? bacteriaData["Prevalence"][key] : "" : ""}
                    labelError={blank_text_error}
                    name={key}
                    >
                    { value.required
                      ? <option value={""}>Select</option >
                      : <option value={"---"}>Select</option >
                    }
                    {value.data.map((dataValue) =>
                      dataValue != "label" && dataValue != "selected" ? (
                        <option key={dataValue} value={dataValue}>
                          {dataValue}
                        </option >
                      ) : null
                    )}
                    </MaterialNativeSelect>
              })
            }
            </Paper>
        </Grid>
        : null}

        { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Enumeration Results</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
                Object.entries(jsonEnumResultsData).map(([key, value]) => {
                  return value.data == null 
                  ?
                  <MaterialInput
                    key={key}
                    name={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    type={value.type || "text"}
                    isrequired={value.required}
                    defaultValue={"Count" in bacteriaData ? [key] in bacteriaData["Count"] ? bacteriaData["Count"][key] : "" : ""}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                  />
                  :
                  <MaterialNativeSelect
                  key={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={"Count" in bacteriaData ? [key] in bacteriaData["Count"] ? bacteriaData["Count"][key] : "" : ""}
                  labelError={blank_text_error}
                  name={key}
                  >
                  { value.required
                    ? <option value={""}>Select</option >
                    : <option value={"---"}>Select</option >
                  }
                  {value.data.map((dataValue) =>
                    dataValue != "label" && dataValue != "selected" ? (
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                    ) : null
                  )}
                  </MaterialNativeSelect>
              })
        }
        </Paper>
        </Grid>
        : null}

        { isInfectivitySelected ?
        <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Infectivity Results</Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
            {
                Object.entries(jsonInfectivityResultsData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={[key] in bacteriaData ? bacteriaData[key] : ""}
                labelError={blank_text_error}
                name={key}
                >
                { value.required
                  ? <option value={""}>Select</option >
                  : <option value={"---"}>Select</option >
                }
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })
            }
          </Paper>
        </Grid>
        : null }

        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm3()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form3Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                UPDATE
              </Button>
        </Grid>
        </Grid>
        </Form>

        </AccordionDetails>
        </Accordion>        
        </Grid>
      </Grid>
    </Grid>

    <Dialog
      open={dialogStudyOpen}
      onClose={() => setDialogStudyOpen(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
      <DialogTitle id="scroll-dialog-title">Study Info</DialogTitle>
      <DialogContent dividers={true}>

      <Form ref={formStudyRef} className={classes.formWide} onSubmit={handleSubmitFormStudy}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
        <Paper className={fixedHeightPaper} elevation={0}>
          {Object.entries(jsonStudyStruct).map(([key, value]) => {
              return value.data == null 
              ?
          <MaterialInput
              key={key}
              name={key}
              label={value.label + (value.required ? required_text : blank_text)}
              type={value.type || "text"}
              disabled={key == "StudyID" ? true : false}
              isrequired={value.required}
              labelError={blank_text_error}
              placeholder={"enter text..."}
              defaultValue={ key in dialogStudyData ? dialogStudyData[key] : ""}
          />
          :
          <MaterialNativeSelect
          key={key}
          label={value.label + (value.required ? required_text : blank_text)}
          defaultValue={ key in dialogStudyData ? dialogStudyData[key] : "" }
          labelError={blank_text_error}
          name={key}
          >
          <option value={""}>Select</option >
          {value.data.map((dataValue) =>
          <option key={dataValue} value={dataValue}>
              {dataValue}
          </option >
          )}
          </MaterialNativeSelect>
          })}
          </Paper>
        </Grid>
        </Grid>
      </Form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row">
          <Button onClick={() => setDialogStudyOpen(false)} size="large" className={classes.customBTNBack}>
            Close
          </Button>
          <Button onClick={() => formStudyRef.current.submitForm()} size="large" className={classes.customBTNNextRegister}>
            Update Study
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>

    <Dialog
        open={dialogOpen}
        onClose={() => clearEndFull()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Registered Data</DialogTitle>
        <DialogContent dividers={true}>
        {openSnackAlert.msg}
        </DialogContent>
        <DialogActions>
          <Grid container direction="column">
            <Button onClick={() => clearAgent()} size="large" className={classes.customBTNNextRegister}>
              Another Agent
            </Button>
            <Button onClick={() => clearFood()} size="large" style={{marginTop: "5px"}} className={classes.customBTNNextRegister}>
              Another Food (same agent)
            </Button>
            <Button onClick={() => clearEndFull()} size="large" style={{marginTop: "5px"}} className={classes.customBTNNextRegister}>
              End
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>


      <Snackbar open={openSnackAlert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})}>
        <Alert onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} severity={openSnackAlert.severity}>
          <AlertTitle>{openSnackAlert.title}</AlertTitle>
          {openSnackAlert.msg}
        </Alert>
      </Snackbar>

    </Grid>
  );
}