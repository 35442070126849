import React from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
//import useStores from "../stores/useStores";
import { observer } from "mobx-react";
import MainLayout from "../Layout/Main";
import StudySearch from "../Pages/Study/Search";
import BacteriaSearch from "../Pages/Bacteria/Search";
import BacteriaSearchLabel from "../Pages/Bacteria/SearchLabel";
import ParasiteSearch from "../Pages/Parasite/Search";
import ParasiteSearchLabel from "../Pages/Parasite/SearchLabel";
import VirusSearch from "../Pages/Virus/Search";
import VirusSearchLabel from "../Pages/Virus/SearchLabel";
import About from "../Pages/MainMenu/About";
import MetaAnalysis  from "../Pages/MainMenu/MetaAnalysis";
import Distributions  from "../Pages/MainMenu/Distributions";

import GC from "../Configs/global";

function AdvancedRoutes() {

  return (
    <BrowserRouter>
      <Switch>
        <MainLayout>
          <Route path={GC.routes.menu.default} exact component={About} />
          <Route path={GC.routes.menu.metaAnalysis} exact component={MetaAnalysis} />
          <Route path={GC.routes.menu.distributions} exact component={Distributions} />

          <Route path={GC.routes.bacteria.search} exact component={BacteriaSearch} />     
          <Route path={GC.routes.bacteria.searchLabel} exact component={BacteriaSearchLabel} />     

          <Route path={GC.routes.parasite.search} exact component={ParasiteSearch} />    
          <Route path={GC.routes.parasite.searchLabel} exact component={ParasiteSearchLabel} />    
  
          <Route path={GC.routes.virus.search} exact component={VirusSearch} />
          <Route path={GC.routes.virus.searchLabel} exact component={VirusSearchLabel} />
        </MainLayout>
      </Switch>
    </BrowserRouter>
  );
}

export default observer(AdvancedRoutes);