import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";

import Colors from "../../Styles/Colors";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: Colors.black,
        backgroundColor: Colors.primaryUltraLight,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  container: {
    maxHeight: 300,
  },
});

export default function TableMaterial({rows=[], columns=[], tableHeight=500}) {
  const classes = useStyles();

  if (columns.length == 0 && rows.length != 0) {
      columns = rows.slice(0, 1);
      rows = rows.slice(1, rows.length);
  }

  return (
    <div>
    <TableContainer className={classes.container} component={Paper}>
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableHead>
          <TableRow>
              {columns.map(col => {
                  return <StyledTableCell>{col}</StyledTableCell>
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
                {Array.isArray(row) ? row.map(cell => {
                    return <TableCell>{cell}</TableCell>
                }):
                    <TableCell>{row}</TableCell>
                }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}