import { observable, action, runInAction } from "mobx";

const appStore = observable({
  user: {
    auth: false,
    id: null,
    givenNames: "",
    surname: "",
    role: "",
  },
  token: null,

  statistics: [],
  feed: [],
});

appStore.getUser = action(() => {
  return {auth: appStore.user.auth, id: appStore.user.id, name: appStore.user.name};
});

appStore.setUser = action((user) => {
  appStore.user.role = user.role.toLowerCase();
  appStore.user.auth = user.auth;
  appStore.user.givenNames = user.givenNames.charAt(0).toUpperCase() + user.givenNames.slice(1);
  appStore.user.surname = user.surname.charAt(0).toUpperCase() + user.surname.slice(1);
  appStore.user.id = user.id;
});

appStore.setToken = action((token) => {
  appStore.token = token;
});

appStore.isAllowed = action(() => {
  if (appStore.user.role == 'advanced' || appStore.user.role == 'admin') {
    return true;
  } else {
    return false;
  }
});

export default appStore;