import React, { useEffect, useState } from 'react';
import { restAPI } from '../../Services/api';
import MaterialMultNativeSelect from '../../Components/Selects/MaterialMultNativeSelect';

export default function CountrySelectMult({ label, name, text_error}) {
    
    const [data,setData] = useState([]);

    // Run Just one time & fill component with country names
    useEffect(() => {
        restAPI.gets().getCountriesNames().then(response => {
            setData(response.data.countries);
        }).catch(error => {

        });
    }, [])

    return (
        <MaterialMultNativeSelect
            label={label || "Food Origin"}
            name={name || "FoodOrigin"}
            labelError={text_error || ""}
            multiple
            inputProps={{
                style: {
                    padding: 10,
                    minHeight: "150px",
                }
            }}
            >
            <option value={""}>All</option>
            {data.map((country) =>
                <option key={country} value={country}>
                    {country}
                </option >
            )}
        </MaterialMultNativeSelect>
    );
}