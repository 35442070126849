import React from "react";
//import useStores from "../stores/useStores";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import { Typography, Box, Paper, Grid, Divider } from "@material-ui/core";

import Colors from "../../../Styles/Colors";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
}));

function MetaAnalysis() {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper}>
          <Box mt={2} ml={2}>
            <Box mb={2}>
            <Typography variant="h4">Bacteria</Typography>
            </Box>
            <Divider />
            <Box mt={2} >
              <Grid container direction="row" alignItems="center">
              <DoubleArrowIcon style={{marginRight: '5px'}}/>
              <Typography variant="h6">
                <a href="http://fsqa.esa.ipb.pt/shiny/meta-analysis/bacteria" target="blank">Meta Analysis of Occurrence</a>
              </Typography>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default observer(MetaAnalysis);
