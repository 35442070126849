import React, {useEffect,useState} from "react";
import AdvancedRoutes from "./Routes/AdvancedRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import BasicRoutes from "./Routes/BasicRoutes";
import NonUserRoutes from "./Routes/NonUserRoutes";

import { observer } from "mobx-react";
import useStores from "./Stores/useStore";

function App() {
  const { appStore } = useStores();

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    const loggedToken = localStorage.getItem("token");
    try {
      if (loggedInUser) {
        appStore.setUser(JSON.parse(loggedInUser));
        appStore.setToken(loggedToken);
      }
    } catch (e) {

    }
  }, []);

  //console.log(appStore.getUser());
  return appStore.user.auth ? (
    appStore.user.role == "admin" ? (
      <AdminRoutes />
    ) : appStore.user.role == "advanced" ? (
      <AdvancedRoutes />
    ) : (
      <BasicRoutes />
    )
  ) : (
    <NonUserRoutes />
  );
}

export default observer(App);
