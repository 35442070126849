import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

//import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import api, {restAPI} from '../../../Services/api';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

import Button from "@material-ui/core/Button";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Scope } from "@unform/core";
import { Form } from "@unform/web";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MaterialInput from "../../../Components/Inputs/MaterialInput";
//import MaterialSelect from "../../../Components/Selects/MaterialSelect";
import MaterialNativeSelect from "../../../Components/Selects/MaterialNativeSelect";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CustomSelect from "../../../Components/Selects/CustomSelect";

import SelectCountry from "../../../Components/CountrySelect";

import CustomAutoComplete from "../../../Components/Selects/CustomAutoComplete";

//import json_agent_count from '../../../Json/Bacteria/agent_count_tree.json';
//import json_general_info from "../../../Json/Bacteria/food_characteristics.json";
//import json_agent_prevalence from '../../../Json/Bacteria/agent_prevalence_tree.json';
//import json_foods from "../../../Json/Bacteria/foodclass_tree.json";

//import json_agent from "../../../Json/Bacteria/agent_tree.json";
//import json_agent_general from "../../../Json/Bacteria/agent_general.json";

//import json_general_results from "../../../Json/Bacteria/general_results.json";
//import json_general_prevalence from "../../../Json/Bacteria/prevalence_data_tree.json";
//import json_general_count from "../../../Json/Bacteria/count_data_tree.json";

import Colors from "../../../Styles/Colors";
import Texts from "../../../Styles/Texts";

import * as Yup from 'yup';
import { attempt } from "lodash";
import CountrySelect from "../../../Components/CountrySelect";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    backgroundColor: Colors.grayUltraLight,
  },
  dividerHeader: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  disabledHeader: {
    backgroundColor: Colors.grayMedium,
  },
  expandedHeader: {
    
  },
  doneStepIcon: {
    color: Colors.primaryDarkHigh
  },
  selectEmpty: {
    textAlign: "left",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    color: Colors.grayDark,
    fontWeight: 'bold',
  },
  textInputControl: {
    fontSize: "25px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  customBTNBack: {
    backgroundColor: Colors.grayDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "100px",
    "&:hover": {
      backgroundColor: Colors.grayHigh,
    },
  },
  customBTNNextRegister: {
    backgroundColor: Colors.secondaryDark,
    color: Colors.white,
    marginRight: theme.spacing(1),
    minWidth: "200px",
    "&:hover": {
      backgroundColor: Colors.secondaryUltraDark,
    },
  },
  formWide: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function BacteriaNew() {
  const blank_text = Texts.leave_blank;
  const required_text = Texts.required;
  const blank_text_error = Texts.blank_error;

  const form1Ref = useRef(null);
  const form2Ref = useRef(null);
  const form3Ref = useRef(null);
  const formStudyRef = useRef(null);

  const formRef = useRef(null);

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [formData,setFormData] = useState({
    form1: null,
    form2: null,
    form3: null
  });

  const [dialogData,setDialogData] = useState({
    open: false,
    type: "normal",
    loading: false,
    title: "",
    msg: "",
  });

  // APIs JSON Data
  const [jsonstudies, setJsonStudies] = useState([]); // Study IDs
  const [jsonStudyStruct,setJsonStudyStruct] = useState([]); // Study Struct

  const [jsonFoods, setJsonFoods] = useState({}); // Food Info [TREE]
  const [jsonFoodCharacs,setJsonFoodCharacs] = useState({}); // Food Chacaracteristics

  const [jsonAgent,setJsonAgent] = useState({}); // Agent Info [TREE]
  const [jsonAgentData,setJsonAgentData] = useState({}) // Agent Info
  const [jsonResultsData,setjsonResultsData] = useState({}); // Results Info
  const [jsonPrevalenceData,setjsonPrevalenceData] = useState({});
  const [jsonCountData,setJsonCountData] = useState({});
  const [jsonFullStruct,setJsonFullStruct] = useState({});

  const [openSnackAlert,setOpenSnackAlert] = useState({
    open: false,
    severity: "info",
    title: "",
    msg: "",
  });

  // Which Form will be expanded in Page -> 1: Form1 - 2: Form2 - 3: Form3
  const [formExpanded, setFormExpanded] = useState(1); 

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogStudyOpen, setDialogStudyOpen] = useState(false);
  const [dialogStudyData,setDialogStudyData] = useState({});

  const [selectedStudy, setSelectedStudy] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // 0: Prevalence  | 1: Count | 2: Both
  const [essayType, setEssayType] = useState(-1);

  // State of Full Structure (for POST the final data in database)
  const [dataFullStruct,setDataFullStruct] = useState({});

  // Tree & State of FOODS
  const [data, setData] = useState(null);
  const [dataAux, setDataAux] = useState([]);

  // Tree & State of AGENTS
  const [selectedAgent, setSelectedAgent] = useState(null);

  // Tree & State of PREVALENCE
  const [jsonEssayPrevalence, setJsonEssayPrevalence] = useState(null);
  const [dataPrevalenceEssay, setDataPrevalenceEssay] = useState(null);
  const [dataAuxEssayPrevalence, setDataAuxEssayPrevalence] = useState([
    jsonEssayPrevalence,
  ]);

  // Tree & States of COUNT
  const [jsonEssayCount, setJsonEssayCount] = useState(null);
  const [dataCountEssay, setDataCountEssay] = useState(null);
  const [dataAuxEssayCount, setDataAuxEssayCount] = useState([jsonEssayCount]);

  useEffect(() => {
    callStudyIdAPI(0);
    callFoodAPI(0);
    callBacteriaAPI(0);
    callStudyStructAPI(0);
  },[]);

  const setDialogErrorMaxAttempts = () => {
    setDialogData({
      open: true,
      type: "error",
      loading: false,
      title: "Error",
      msg: Texts.dialog_errors.max_attempts,
    });
  }

  const callBacteriaAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonAgent({});
      setJsonAgentData({});
      setjsonResultsData({});
      setjsonPrevalenceData({});
      setJsonCountData({});
      return ;
    }

    // Run API Call
    api.get('/bacteria/struct/all').then(response => {
      //console.log("recebeu:", response.data);
      if (!response.data) {
          //console.log("entrou aqui");
      }
      setJsonAgent(response.data.bacteria_tree);
      setJsonAgentData(response.data.bacteria_data);
      setjsonResultsData(response.data.results_data);
      setjsonPrevalenceData(response.data.prevalence_data);
      setJsonCountData(response.data.count_data);
      setJsonFullStruct(response.data.full_structure);
      setDataFullStruct(response.data.full_structure);
      return;
    }).catch(err => {
      callBacteriaAPI(attempt+1);
    });

  };

  const callFoodAPI = (attempt) => {
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setDataAux([]);
      setJsonFoods(null);
      setJsonFoodCharacs({});
      return ;
    }
  
    // Run API Call
    api.get('/food/struct/all').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonFoods(response.data.food_tree);
        setDataAux([response.data.food_tree]);
        setJsonFoodCharacs(response.data.food_data);
        //setJsonFoods(response.data.food_data);
        return;
    }).catch(err => {
        callFoodAPI(attempt+1);
    });
  };

  const callStudyIdAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudies([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/list/id_only').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonStudies(response.data);
        return;
    }).catch(err => {
        callStudyIdAPI(attempt+1);
    });
  }

  const callStudyStructAPI = (attempt) => {
    // Limit to Max 3 Attempts
    if (attempt >= 2) {
      setDialogErrorMaxAttempts();
      setJsonStudyStruct([]);
      return ;
    }
  
    // Run API Call
    api.get('/study/struct/study_data').then(response => {
        //console.log("recebeu:", response.data);
        if (!response.data) {
            //console.log("entrou aqui");
        }
        setJsonStudyStruct(response.data);
        return;
    }).catch(err => {
        callStudyStructAPI(attempt+1);
    });
  }

  const callGetStudyInfoAPI = () => {
    try {
      let getStudyID = form1Ref.current.getFieldValue("StudyID");
      if (getStudyID == "") {
        setOpenSnackAlert({
          open: true,
          severity: "error",
          title: "Error",
          msg: "No StudyID selected"
        });
      } else {
        // Run API Call
        api.get('/study/list/id',{params: {StudyID: getStudyID}, headers: {authorization: authToken}})
        .then((response) => {
          //console.log("recebeu:", response.data);
          setDialogStudyData(response.data);
          setDialogStudyOpen(true);
          return;
        }).catch(err => {
  
        });
      }
    } catch (err) {
      //console.log(err);
    }
  }

  const handleAgent = (event) => {
    if (event == "null" || event == "") {
      setEssayType(-1);
      setSelectedAgent(null);

      setJsonEssayCount(null);
      setDataAuxEssayCount([null]);

      setJsonEssayPrevalence(null);
      setDataAuxEssayPrevalence([null]);

    } else {
      setJsonEssayCount(jsonAgent[event].count);
      setDataAuxEssayCount([jsonAgent[event].count]);

      setJsonEssayPrevalence(jsonAgent[event].prevalence);
      setDataAuxEssayPrevalence([jsonAgent[event].prevalence]);

      setSelectedAgent(event);
    }
  };

  const removeNullFields = (obj) => {
    Object.entries(obj).forEach(([key, val])  =>
      (val && typeof val === 'object') && removeNullFields(val) ||
      (val === null || val === "") && delete obj[key]
    );
    return obj;
  };

  async function handleSubmitFormStudy(data, {reset}, event) {
    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = formStudyRef.current.getFieldRef(value).current || formStudyRef.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formStudyRef.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (formStudyRef.current.getFieldRef(value).required) {
              formStudyRef.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            formStudyRef.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      callUpdateStudyAPI(data);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }
  
  async function handleSubmitForm1(data, { reset }, event) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;

    Object.keys(data).map((value,index) => {
        let isTextField = form1Ref.current.getFieldRef(value).current || form1Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form1Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form1Ref.current.getFieldRef(value).required) {
              form1Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form1Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      // CONTINUAR DAQUI EM DIANTE *****************************************************************************
      /*let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(data).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = data[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = data[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = data[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);

      let finalData =  { ...data_formatted }
      //console.log("Final:",finalData);
      */
      // Set Form1 Data
      setFormData({...formData, form1: data });
      // Open Form2
      setFormExpanded(2);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm2(data, { reset }, event) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form2Ref.current.getFieldRef(value).current || form2Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form2Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form2Ref.current.getFieldRef(value).required) {
              form2Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form2Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      // Set Form2 Data
      /*let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(data).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = data[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = data[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = data[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);

      let finalData =  { ...formData.form1, ...data_formatted }
      //console.log("Final:",finalData);
      */
      setFormData({...formData, form2: data });
      // Open Form3
      setFormExpanded(3);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  async function handleSubmitForm3(data, { reset }, event) {
    //console.log(data);

    let resErrors = {}
    let haveErrors = false;
    Object.keys(data).map((value,index) => {
        let isTextField = form3Ref.current.getFieldRef(value).current || form3Ref.current.getFieldRef(value).props || true;

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            form3Ref.current.setFieldError(value, true);
            haveErrors = true;
            resErrors[value] = Yup.string().required();
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        } else {
          if (data[value] === "") {
            if (form3Ref.current.getFieldRef(value).required) {
              form3Ref.current.setFieldError(value, true);
              haveErrors = true;
              resErrors[value] = Yup.string().required();
            }
          } else {
            form3Ref.current.setFieldError(value, false);
          }
        }
    });

    if (!haveErrors) {
      let finalData =  cloneDeep({...formData.form1, ...formData.form2, ...data });
      let data_formatted = cloneDeep(jsonFullStruct);

      Object.keys(finalData).map(key => {
        if (key in dataFullStruct) {
          data_formatted[key] = finalData[key];
        } else if (key in data_formatted["Prevalence"]) {
          data_formatted["Prevalence"][key] = finalData[key];
        } else if (key in data_formatted["Count"]) {
          data_formatted["Count"][key] = finalData[key];
        }
      });
      
      data_formatted = removeNullFields(data_formatted);
      
      // Set Form3 Data
      setFormData({...formData, form3: data });
      // Call Backend For Register
      //setFormExpanded(3);
      //let finalData = prepareStudyJson(formData.form1, formData.form2, data);
      
      //console.log("Final:",data_formatted);
      if (Object.keys(data_formatted['Prevalence']).length === 0) {
        delete data_formatted['Prevalence'];
      } else if (Object.keys(data_formatted['Count']).length === 0) {
        delete data_formatted['Count'];
      }
      //console.log("Final:",data_formatted);

      callRegisterAPI( data_formatted );
      setOpenSnackAlert({...openSnackAlert, msg: "Loading..."});
      setDialogOpen(true);
    } else {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.error_empty_fields
      });
    }
  }

  const prepareStudyJson = (form1,form2,form3) => {
    let finalData = {};
    
    for (let key in form1) {
      if(form1.hasOwnProperty(key)){
        finalData[key] = form1[key];
      }
    }

    if (finalData.essay === "0") {
      finalData.essay = "prevalence";
    } else if (finalData.essay === "1") {
      finalData.essay = "count";
    } else {
      finalData.essay = "both";
    }

    for (let key in form2) {
      if(form2.hasOwnProperty(key)){
        finalData[key] = form2[key];
      }
    }

    for (let key in form3) {
      if(form3.hasOwnProperty(key)){
        finalData[key] = form3[key];
      }
    }
    
    return finalData;
  }

  const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMzE1ODVhYjAwNDA5MDkwOGFjNjY1NSIsImlhdCI6MTU5NzA2OTQwMiwiZXhwIjoxNTk3MzI4NjAyfQ.Ya6a6vW1fKLu0lhQom37XlJ9KeZfEJECWAkDbLJ5gaM";


  const callUpdateStudyAPI = (data) => {
    api.post('/study/manage/update', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      //console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.update_sucess
      });

      return setDialogStudyOpen(false);

    }).catch(function (error) {
      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.update_error
      });
    });
  }

  const callRegisterAPI = (data) => {
    setDialogData({
      open: true,
      loading: true,
      type: "normal",
      title: "Registering...",
      msg: "",
    });

    api.post('/bacteria/new', data, {
      headers: {
        'authorization': authToken
      }
    }).then(response => {
      //console.log("recebeu:", response.data);

      setOpenSnackAlert({
        open: true,
        severity: "success",
        title: "Success",
        msg: Texts.snack_msg.register_sucess
      });

      setDialogData({
        open: true,
        type: "sucess",
        loading: false,
        title: "Sucess",
        msg: Texts.page_study_new.dialog_sucess_register,
      });
      return;

    }).catch(function (error) {
      //console.log("ERROR");

      setOpenSnackAlert({
        open: true,
        severity: "error",
        title: "Error",
        msg: Texts.snack_msg.register_error
      });

      let exists = false;
      try {
        if ('response' in error && 'data' in error.response && 'error' in error.response.data) {
              exists = true;
        }       
      } catch {
        exists = false;
      }
     setDialogData({
        open: true,
        loading: false,
        type: "error",
        title: "Error",
        msg: exists ? error.response.data.error : Texts.page_study_new.dialog_error_register,
      });
    });
  }
  

  const handleBackForm2 = () => {
    setFormData({...formData, form1: null, form2: null });
    // Open Form2
    setFormExpanded(1);
  }

  const handleBackForm3 = () => {
    setFormData({...formData, form2: null, form3: null });
    // Open Form2
    setFormExpanded(2);
  }

  const clearAgent = () => {
    setDialogOpen(false);

    setFormData({ form1: null, form2: null, form3: null });
    setFormExpanded(1);

    setData(null);
    setDataAux([jsonFoods]);
    setSelectedCountry(null);

    setEssayType(-1);
    setSelectedAgent(null);
    setJsonEssayCount(null);
    setDataAuxEssayCount([null]);
    setJsonEssayPrevalence(null);
    setDataAuxEssayPrevalence([null]);

    // Clear Form1 (Without STUDYID FIELD)
    Object.keys(formData.form1).map((value,index) => {
      if (value !== "StudyID") {
        form1Ref.current.clearField(value);
        ////console.log(formRef.current.getFieldRef(value));
  
        let isNativeSelect = form1Ref.current.getFieldRef(value).current || true;
        let isAutoComplete = form1Ref.current.getFieldRef(value).props || true;
  
        if (typeof isNativeSelect != 'boolean') {
          ////console.log("NativeSelect");
          form1Ref.current.getFieldRef(value)['current']['value'] = "";
        }
  
        if (typeof isAutoComplete != 'boolean') {
          ////console.log("AutoCompelte");
          form1Ref.current.getFieldRef(value)['state']['value'] = "";
        }
      }
    });

    // Clear Form2
    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    // Clear Form3
    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

  }

  const clearFood = () => {
    setDialogOpen(false);

    setFormData({...formData, form2: null, form3: null });
    setFormExpanded(2);

    setData(null);
    setDataAux([jsonFoods]);
    setSelectedCountry(null);

    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

  }

  const clearEndFull = () => {
    setDialogOpen(false);

    setFormData({form1: null, form2: null, form3: null });
    setFormExpanded(1);

    setData(null);
    setDataAux([jsonFoods]);
    setSelectedCountry(null);

    setEssayType(-1);
    setSelectedAgent(null);
    setJsonEssayCount(null);
    setDataAuxEssayCount([null]);
    setJsonEssayPrevalence(null);
    setDataAuxEssayPrevalence([null]);

    // Clear Form1 (Without STUDYID FIELD)
    Object.keys(formData.form1).map((value,index) => {
      form1Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form1Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form1Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form1Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form1Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    Object.keys(formData.form2).map((value,index) => {
      form2Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form2Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form2Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form2Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form2Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

    Object.keys(formData.form3).map((value,index) => {
      form3Ref.current.clearField(value);
      ////console.log(formRef.current.getFieldRef(value));

      let isNativeSelect = form3Ref.current.getFieldRef(value).current || true;
      let isAutoComplete = form3Ref.current.getFieldRef(value).props || true;

      if (typeof isNativeSelect != 'boolean') {
        ////console.log("NativeSelect");
        form3Ref.current.getFieldRef(value)['current']['value'] = "";
      }

      if (typeof isAutoComplete != 'boolean') {
        ////console.log("AutoCompelte");
        form3Ref.current.getFieldRef(value)['state']['value'] = "";
      }
    });

  }
  
  async function handleSubmit(data, { reset }, event) {
    ////console.log(Yup.object());
    //event.preventDefault();
    //event.stopPropagation();
    //event.nativeEvent.stopImmediatePropagation();

    //console.log(data);

    let resErrors = {}
    Object.keys(data).map((value,index) => {
        ////console.log(value,"->",formRef.current.getFieldRef(value));
        ////console.log(typeof formRef.current.getFieldRef(value));
        ////console.log(value,"->",);
        ////console.log("-----");

        let isTextField = formRef.current.getFieldRef(value).current || formRef.current.getFieldRef(value).props || true;
        ////console.log(value," -> ",isTextField);

        if (typeof isTextField != 'boolean') {
          if (data[value] === "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        } else {
          if (data[value] === "") {
            if (formRef.current.getFieldRef(value).required) {
              formRef.current.setFieldError(value, true);
              resErrors[value] = Yup.string().required();
            }
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        }
        

       /* if (typeof isTextField != 'boolean') {
          if (data[value] == "") {
            formRef.current.setFieldError(value, true);
            resErrors[value] = Yup.string().required();
          } else {
            formRef.current.setFieldError(value, false);
            ////console.log(formRef.current);
          }
        } else {
        }*/
    });
  }

  const handleChange = (label, index, event) => {
    ////console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setData({ ...data, [label]: event });

      if (dataAux[index][event] != null) {
        let newData = dataAux;
        newData = newData.slice(0, index + 1);

        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";

        setDataAux(newData);
        /*} else {
            //console.log("Tentou Alterar");
          }*/
      } else {
        let newData = dataAux;
        ////console.log("Entrada: ",newData);
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        ////console.log("Saida: ",newData);
        setDataAux(newData);
      }
    } else {
      ////console.log("entrou1")
      let newData = dataAux;
      ////console.log("Entrada: ",newData);
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      ////console.log("Saida: ",newData);
      setDataAux(newData);
    }
  };

  const handleEssayType = (event) => {
    if (event.target.value === "") {
      setEssayType(null);
    } else {
      setEssayType(event.target.value);
    }
  };

  const handleEssayPrevalence = (label, index, event) => {
    //console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setDataPrevalenceEssay({ ...dataPrevalenceEssay, [label]: event });
      if (dataAuxEssayPrevalence[index][event] != null) {
        let newData = dataAuxEssayPrevalence;
        //console.log("Entrada: ", newData);
        newData = newData.slice(0, index + 1);
        //console.log("MeioTermo", newData);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        //console.log("Saida: ", newData);
        setDataAuxEssayPrevalence(newData);
      } else {
        let newData = dataAuxEssayPrevalence;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setDataAuxEssayPrevalence(newData);
      }
    } else {
      let newData = dataAuxEssayPrevalence;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setDataAuxEssayPrevalence(newData);
    }
  };

  const handleEssayCount = (label, index, event) => {
    //console.log("[", index, "] Alterou: ", label, " - ", event);
    if (event != "null") {
      setDataCountEssay({ ...dataCountEssay, [label]: event });
      if (dataAuxEssayCount[index][event] != null) {
        let newData = dataAuxEssayCount;
        //console.log("Entrada: ", newData);
        newData = newData.slice(0, index + 1);
        //console.log("MeioTermo", newData);
        newData[index]["selected"] = event;
        newData.push(newData[index][event]);
        newData[index][event]["selected"] = "null";
        //console.log("Saida: ", newData);
        setDataAuxEssayCount(newData);
      } else {
        let newData = dataAuxEssayCount;
        newData = newData.slice(0, index + 1);
        newData[index]["selected"] = event;
        setDataAuxEssayCount(newData);
      }
    } else {
      let newData = dataAuxEssayCount;
      newData[index]["selected"] = "null";
      newData = newData.slice(0, index + 1);
      setDataAuxEssayCount(newData);
    }
  };

  //const [jsontree,setJsonTree] = useState(jsonFoods);

  const clearData = () => {
    setDataAux([jsonFoods]);
    //setJsonTree(jsonFoods);
    setData(null);
  };

  return (
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
      <Accordion defaultExpanded={true} expanded={formExpanded === 1} className={formExpanded === 1 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Grid container alignItems="center">
            {formData.form1 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"1. Study, Agent & Essay"}</Typography>
        </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form1Ref} className={classes.formWide} onSubmit={handleSubmitForm1}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Study Info</Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
          <Grid container direction="row">
          <Grid item container direction="column">
          <CustomSelect
            label={"Select Study ID" + required_text}
            defaultValue={""}
            fullW
            name={"StudyID"}
            items={jsonstudies}
            labelError={blank_text_error}
            clearable={true}
          />
          </Grid>
          <Grid item>
          <Button
            onClick={() => callGetStudyInfoAPI()}
            variant="contained"
            size="large"
            //disabled={rows.length == 0 ? true : false}
            className={classes.customBTNNextRegister}
          >
            Study Info
          </Button>
          </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Agent Info</Typography>
                <Divider />
                <Paper className={fixedHeightPaper}>
                    <MaterialNativeSelect
                      name="Bacterium"
                      label={"Select Agent" + required_text}
                      labelError={blank_text_error}
                      onChange={(event) => handleAgent(event.target.value)}
                    >
                      <option value={""}>Select</option >
                      {Object.keys(jsonAgent).map(key => 
                        key != "label" && key != "selected" ? (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ) : null
                      )}
                    </MaterialNativeSelect>

                { 
                Object.entries(jsonAgentData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={""}
                labelError={blank_text_error}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                <option key={dataValue} value={dataValue}>
                  {dataValue}
                </option >
                )}
                </MaterialNativeSelect>
                })}

                {selectedAgent ? 
                  <MaterialNativeSelect
                  name="essay"
                  label={"Select Essay"  + required_text}
                  labelError={blank_text_error}
                  onChange={handleEssayType}
                >
                  <option value={""}>Select</option>
                  <option value={"0"}>Prevalence</option>
                  <option value={"1"}>Count</option>
                  <option value={"2"}>Both</option>
                </MaterialNativeSelect>
                : null}

                </Paper>
              </Grid>
        { essayType == 2 || essayType == 0 ?
        <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Prevalence</Typography>
              <Divider />
              <Paper className={fixedHeightPaper}>
              { 
                    (dataAuxEssayPrevalence.map((json,index) => {
                      return (
                        <MaterialNativeSelect
                          key={json.label}
                          label={json.label  + required_text}
                          labelError={blank_text_error}
                          onChange={(event) => handleEssayPrevalence(json.label,index,event.target.value)}
                          name={json.label}
                        >
                          <option value={""}>Select</option >
                          {Object.keys(json).map(key => 
                            key != "label" && key != "selected" ? (
                              <option key={key} value={key}>
                                {key}
                              </option >
                            ) : null
                          )}
                        </MaterialNativeSelect>
                    )}))
              }
              </Paper>
            </Grid>
        : null}

        { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Essay Count</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
              (dataAuxEssayCount.map((json,index) => {
                return (
                  <MaterialNativeSelect
                    key={json.label}
                    label={json.label + required_text}
                    labelError={blank_text_error}
                    onChange={(event) => handleEssayCount(json.label,index,event.target.value)}
                    name={json.label}
                  >
                    <option value={""}>Select</option >
                    {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                        <option key={key} value={key}>
                          {key}
                        </option >
                      ) : null
                    )}
                  </MaterialNativeSelect>
              )}))
        }
        </Paper>
        </Grid>
        : null}

        <Grid item xs={12} md={12} container justify="flex-end">
        <Button
            onClick={() => form1Ref.current.submitForm()}
            variant="contained"
            size="large"
            className={classes.customBTNNextRegister}
          >
            Next
        </Button>
        </Grid>

        </Grid>
        </Form>

        </AccordionDetails>
        </Accordion>
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 2} className={formExpanded === 2 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form2 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"2. Food"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form2Ref} className={classes.formWide} onSubmit={handleSubmitForm2}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Info
          </Typography>
          <Divider />
          <Paper className={fixedHeightPaper}>
            <CountrySelect 
              name={"FoodOrigin"} 
              label={"Food Origin" + required_text}
              labelError={blank_text_error} />
            { 
                (dataAux.map((json,index) => {
                  return (
                  <MaterialNativeSelect
                    key={json.label}
                    label={json.label + required_text}
                    labelError={blank_text_error}
                    onChange={(event) => handleChange(json.label,index,event.target.value)}
                    name={json.label}
                  >
                    <option value={""}>Select</option >
                    {Object.keys(json).map(key => 
                      key != "label" && key != "selected" ? (
                        <option key={key} value={key}>
                          {key}
                        </option >
                      ) : null
                    )}
                  </MaterialNativeSelect>
                )}))
            }
            {/*<Button onClick={clearData}>Clear Data</Button>*/}
          </Paper>
        </Grid>
          
        <Grid item xs={12} md={12}>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Food Characteristics
          </Typography>
          <Divider />
          {/*//console.log("RODOU")*/}
          <Paper className={fixedHeightPaper}>
          { 
                Object.entries(jsonFoodCharacs).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={""}
                labelError={blank_text_error}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm2()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form2Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                Next
              </Button>
        </Grid>
        </Grid>
        </Form>
        </AccordionDetails>
        </Accordion>      
        </Grid>

        <Grid item xs={12} md={12}>
        <Accordion expanded={formExpanded === 3} className={formExpanded === 3 ? classes.expandedHeader : classes.disabledHeader}>
        <AccordionSummary
          className={classes.formWide}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container alignItems="center">
            {formData.form3 === null ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon className={classes.doneStepIcon}/> }
            <Divider className={classes.dividerHeader} orientation="vertical" />
            <Typography variant={"h6"} className={classes.heading}>{"3. Results"}</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor:Colors.backgroundColor}}>
        <Form ref={form3Ref} className={classes.formWide} onSubmit={handleSubmitForm3}>

        <Grid container spacing={3}>
        
        <Grid item xs={12} md={12}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>General Results</Typography>
            <Divider />
            <Paper className={fixedHeightPaper}>
            { 
                Object.entries(jsonResultsData).map(([key, value]) => {
                  return value.data == null 
                  ?
                <MaterialInput
                  key={key}
                  name={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  type={value.type || "text"}
                  isrequired={value.required}
                  labelError={blank_text_error}
                  placeholder={"enter text..."}
                />
                :
                <MaterialNativeSelect
                key={key}
                label={value.label + (value.required ? required_text : blank_text)}
                defaultValue={""}
                labelError={blank_text_error}
                name={key}
                >
                <option value={""}>Select</option >
                {value.data.map((dataValue) =>
                    <option key={dataValue} value={dataValue}>
                      {dataValue}
                    </option >
                )}
                </MaterialNativeSelect>
            })}
            </Paper>
          </Grid>
        
        { essayType == 2 || essayType == 0 ?
        <Grid item xs={12} md={12}>
              <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Prevalence Results</Typography>
              <Divider />
              <Paper className={fixedHeightPaper}>
              { 
                  Object.entries(jsonPrevalenceData).map(([key, value]) => {
                    return value.data == null 
                    ?
                  <MaterialInput
                    key={key}
                    name={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    type={value.type || "text"}
                    isrequired={value.required}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                  />
                  :
                  <MaterialNativeSelect
                  key={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={""}
                  labelError={blank_text_error}
                  name={key}
                  >
                  <option value={""}>Select</option >
                  {value.data.map((dataValue) =>
                    dataValue != "label" && dataValue != "selected" ? (
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                    ) : null
                  )}
                  </MaterialNativeSelect>
              })}
              </Paper>
            </Grid>
        : null}

        { essayType == 2 || essayType == 1 ?
        <Grid item xs={12} md={12}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>Count Results</Typography>
        <Divider />
        <Paper className={fixedHeightPaper}>
        { 
                  Object.entries(jsonCountData).map(([key, value]) => {
                    return value.data == null 
                    ?
                  <MaterialInput
                    key={key}
                    name={key}
                    label={value.label + (value.required ? required_text : blank_text)}
                    type={value.type || "text"}
                    isrequired={value.required}
                    labelError={blank_text_error}
                    placeholder={"enter text..."}
                  />
                  :
                  <MaterialNativeSelect
                  key={key}
                  label={value.label + (value.required ? required_text : blank_text)}
                  defaultValue={""}
                  labelError={blank_text_error}
                  name={key}
                  >
                  <option value={""}>Select</option >
                  {value.data.map((dataValue) =>
                    dataValue != "label" && dataValue != "selected" ? (
                      <option key={dataValue} value={dataValue}>
                        {dataValue}
                      </option >
                    ) : null
                  )}
                  </MaterialNativeSelect>
              })}
        </Paper>
        </Grid>
        : null}

        <Grid item xs={12} md={12} container justify="flex-end">
              <Button
                onClick={() => handleBackForm3()}
                variant="contained"
                size="large"
                className={classes.customBTNBack}
              >
                Back
              </Button>
              <Button
                onClick={() => form3Ref.current.submitForm()}
                variant="contained"
                size="large"
                className={classes.customBTNNextRegister}
              >
                Register
              </Button>
        </Grid>
        </Grid>
        </Form>

        </AccordionDetails>
        </Accordion>        
        </Grid>
      </Grid>
    </Grid>

    <Dialog
      open={dialogStudyOpen}
      onClose={() => setDialogStudyOpen(false)}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      >
      <DialogTitle id="scroll-dialog-title">Study Info</DialogTitle>
      <DialogContent dividers={true}>

      <Form ref={formStudyRef} className={classes.formWide} onSubmit={handleSubmitFormStudy}>
        <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
        <Paper className={fixedHeightPaper} elevation={0}>
          {Object.entries(jsonStudyStruct).map(([key, value]) => {
              return value.data == null 
              ?
          <MaterialInput
              key={key}
              name={key}
              label={value.label + (value.required ? required_text : blank_text)}
              type={value.type || "text"}
              disabled={key == "StudyID" ? true : false}
              isrequired={value.required}
              labelError={blank_text_error}
              placeholder={"enter text..."}
              defaultValue={ key in dialogStudyData ? dialogStudyData[key] : ""}
          />
          :
          <MaterialNativeSelect
          key={key}
          label={value.label + (value.required ? required_text : blank_text)}
          defaultValue={ key in dialogStudyData ? dialogStudyData[key] : "" }
          labelError={blank_text_error}
          name={key}
          >
          <option value={""}>Select</option >
          {value.data.map((dataValue) =>
          <option key={dataValue} value={dataValue}>
              {dataValue}
          </option >
          )}
          </MaterialNativeSelect>
          })}
          </Paper>
        </Grid>
        </Grid>
      </Form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row">
          <Button onClick={() => setDialogStudyOpen(false)} size="large" className={classes.customBTNBack}>
            Close
          </Button>
          <Button onClick={() => formStudyRef.current.submitForm()} size="large" className={classes.customBTNNextRegister}>
            Update Study
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>

    <Dialog
        open={dialogOpen}
        onClose={() => clearEndFull()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Registered Data</DialogTitle>
        <DialogContent dividers={true}>
        {openSnackAlert.msg}
        </DialogContent>
        <DialogActions>
          <Grid container direction="column">
            <Button onClick={() => clearAgent()} size="large" className={classes.customBTNNextRegister}>
              Another Agent
            </Button>
            <Button onClick={() => clearFood()} size="large" style={{marginTop: "5px"}} className={classes.customBTNNextRegister}>
              Another Food (same agent)
            </Button>
            <Button onClick={() => clearEndFull()} size="large" style={{marginTop: "5px"}} className={classes.customBTNNextRegister}>
              End
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>


      <Snackbar open={openSnackAlert.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})}>
        <Alert onClose={() => setOpenSnackAlert({...openSnackAlert, open: false})} severity={openSnackAlert.severity}>
          <AlertTitle>{openSnackAlert.title}</AlertTitle>
          {openSnackAlert.msg}
        </Alert>
      </Snackbar>

    </Grid>
  );
}